import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import servicedata from "../../../common/data/services";
import InnerBannerSection from "../../utility/innerPageBanner";
import SectionFour from "../../utility/innerPageSections/sectionFour";
import SectionOne from "../../utility/innerPageSections/sectionOne";
import SectionThree from "../../utility/innerPageSections/sectionThree";
import SectionTwo from "../../utility/innerPageSections/sectionTwo";

function ServicePage() {
  const data = servicedata;
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="main-page-wrapper">
        {data.service.map((service) => (
          <div key={service.id}>
            {"/services/" + service.id === location.pathname && (
              <InnerBannerSection
                text1={service.innerBanner.text1}
                text2={service.innerBanner.text2}
                currentPage={service.currentPage}
                image={service.innerBanner.image}
                imageText={service.innerBanner.imageText}
                type="left"
              />
            )}
            {"/services/" + service.id === location.pathname &&
              service.section1 && <SectionOne data={service.section1} />}
            {"/services/" + service.id === location.pathname &&
              service.section2 && <SectionTwo data={service.section2} />}
            {"/services/" + service.id === location.pathname &&
              service.section3 && <SectionThree data={service.section3} />}
            {"/services/" + service.id === location.pathname &&
              service.section4 && <SectionFour data={service.section4} />}
          </div>
        ))}
      </div>
    </>
  );
}

export default ServicePage;
