import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

function SectionOne(props) {
  const data = props.data;
  return (
    <section className="fancy-feature-twelve section-space">
      <div className="container">
        <div className="row align-items-center align-items-xl-start">
          <div className="col-xl-7 col-md-7 order-md-last">
            <div className="block-style-nine color-two">
              <div className="title-style-three pb-10">
                <ScrollAnimation animateIn="animate__fadeIn">
                  <div className="sc-title">{data.screenTitle}</div>
                  <h2 className="main-title text-capitalize">
                    {data.sectionTitle}
                  </h2>
                  {data.sectionPara && (
                    <p className="mb-0">{data.sectionPara}</p>
                  )}
                </ScrollAnimation>
              </div>
              <ScrollAnimation animateIn="animate__fadeIn">
                {data.sectionListItems && (
                  <ul className="style-none list-item">
                    {data.sectionListItems.map((items) => (
                      <li key={items.id}>{items.text}</li>
                    ))}
                  </ul>
                )}
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-xl-5 col-md-7  order-md-first">
            <ScrollAnimation animateIn="animate__fadeIn">
              <img
                src={data.sectionImage}
                alt={data.sectionImageText}
                className="transform-img-meta"
              />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionOne;
