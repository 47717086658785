import images from "../../data/images";

const services = {
  service: [
    {
      id: "banking-finance",
      currentPage: "Banking and Finance",
      innerBanner: {
        text1: "Banking and",
        text2: "Finance",
        image: images.banking.banking_img_1,
        imageText: "",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Banking and Finance",
        sectionListItems: [
          {
            id: 1,
            text: "Finsol’s integrated services and solutions for across banking and financial applications, can help firms embrace the growing shift towards digitalization.",
          },
          {
            id: 2,
            text: "The regulations in the financial markets are constantly changing with new mergers. It becomes crucial for organizations to stay on top of the new regulations.",
          },
          {
            id: 3,
            text: "This is where Finsol steps in, as we provide unique and updated solutions to for online cards and payments transactions.",
          },
          {
            id: 4,
            text: "With changing trends in modernization of payment methods in cards & payments industry and with every new technology.",
          },
        ],
        sectionImage: images.banking.banking_img_2,
        sectionImageText: "",
      },
      section2: {
        sectionTitle: "Enabling the success of today's enterprise",
        sectionPara:
          " Finsol can help by working with you to anticipate challenges, develop and implement strategies, and embrace the shift to a higher bottom line for the future of banking. To realize the true potential of the digital economy, you’ll need to do more than keep pace. You have to lead the way. The different services that Finsol offers include debit and credit cards, store value and smart cards, private label cards, and much more. We can also help provide support for payment gateways and auction sites. ",
        sectionImage: images.banking.banking_img_3,
        subSection: {
          title: "",
          text: " ",
        },
      },
      section3: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Your Ideas. Our Experience",
        sectionPara:
          " Our experience covers all facets of cards and payments life cycle. We command an experience of over 20 years in this domain that has been cultivated through engagements with issuers, acquirers, schemes and third-party payment processors across the globe through end to end services including Consulting, application maintenance, testing and 24X7 application support.",
        sectionListItems: [],
        sectionImage: images.banking.banking_img_4,
        sectionImageText: "",
      },
    },
    {
      id: "health-care",
      currentPage: "Health and care",
      innerBanner: {
        text1: "Health and",
        text2: "care",
        image: images.healthcare.health_img_2,
        imageText: "",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Health and Care",
        sectionPara: " ",
        sectionListItems: [
          {
            id: 1,
            text: "Finsol helps Hospitals and other related organizations capitalize on the right technology solutions to streamline clinical workflows.  ",
          },
          {
            id: 2,
            text: "Trusted clinical technology and evidence-based solutions that drive effective decision-making and outcomes across healthcare. ",
          },
          {
            id: 3,
            text: "Solutions from Finsol can solve today's health organization challenges and improve health outcomes for patients around the world.",
          },
        ],
        sectionImage: images.healthcare.health_img_1,
        sectionImageText: "",
      },
      section2: {
        sectionTitle: "Processing Industry",
        sectionPara:
          "Finsol is about working smarter. Its time to take a phenomenal approach to your back-office finance operations with touchless processes and intelligent automation. Every business is dynamic and so are its needs. May be that's why most manufacturers are booking for an ERP that's dynamic too. Finsol is your place then. Finsol offers a range of spectacular ERP solutions built for process manufacturers. ",
        sectionImage: images.healthcare.health_img_3,
        subSection: {
          title: "",
          text: " Health care ERP System is a broader ERP platform that’s categorically designed to assist the exclusive needs of health care businesses. FINSOL Enterprise resource planning (ERP) is a software system that helps organizations automate and manage core business processes for optimal performance.  FINSOL Enterprise resource planning (ERP) is a software system that helps organizations automate and manage core business processes for optimal performance.",
        },
      },
      section3: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "EXPLORE THE CHALLENGES WE HELP SOLVE FOR",
        sectionPara:
          "Ensuring Data Privacy of Patients. Reducing Clinical Variation and Health Care Costs. Finsol is a one-stop option to meet all your clinic management facilities. Choose us to provide high-tech possibilities at comfortable charges. ",
        sectionListItems: [],
        sectionImage: images.healthcare.health_img_4,
        sectionImageText: "",
      },
    },
  ],
};

export default services;
