import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Home from "../../../../common/data/home";
function FeatureSectionNineteen() {
  const data = Home.featureSectionNineteen;
  return (
    <div className="fancy-feature-nineteen position-relative section-space">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xxl-6 col-lg-6 col-md-7">
            <div className="block-style-thirteen">
              <div className="title-style-three pb-15">
                <ScrollAnimation animateIn="animate__fadeIn">
                  <div className="sc-title">Business</div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeIn">
                  <h2 className="main-title">
                    Get the most out of your business with <span>Finsol.</span>
                  </h2>
                </ScrollAnimation>
              </div>
              <ul className="style-none list-item">
                <li>
                  <div className="numb tran3s">1</div>
                  <h6>VisionPlus Product Services</h6>
                  <span>
                    VisionPlus has a strong following among financial
                    institutions across the globe that helps them to deliver a
                    superior customer experience
                  </span>
                </li>
                <li>
                  <div className="numb tran3s">2</div>
                  <h6>
                    Application Services that Increase Productivity and
                    Efficiency
                  </h6>
                  <span>
                    We focus more on understanding the client’s business goals
                    and develop customized application services that improve
                    productivity, efficiency, and overall effectiveness that
                    translates into faster time to value.
                  </span>
                </li>
                <li>
                  <div className="numb tran3s">3</div>
                  <h6>Testing Services</h6>
                  <span>
                    With Finsol’s software testing services, you can uncover
                    software bugs before your clients do.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xxl-6 col-lg-6 col-md-5">
            <ScrollAnimation animateIn="animate__fadeIn">
              <img src={data} alt="" className="transform-img-meta margin " />
            </ScrollAnimation>
          </div>
        </div>
      </div>

      <div className="shapes oval-one"></div>
      <div className="shapes oval-two"></div>
      <img src="images/shape/shape_35.svg" alt="" className="shapes bg-shape" />
    </div>
  );
}

export default FeatureSectionNineteen;
