import images from "../../data/images";

const about = {
  about_company: {
    sectionHeading: "Who We are",
    sectionPara:
      "Finsol is a software development company specialized inproviding custom digital solutions for the businesses acrossthe world.",
    sectionListItems: [
      {
        text: "Having expertise in banking, card processing technologies and product development.",
      },
      {
        text: " Finsol Solutions land as the best cost-effective solutions across the industry.",
      },
      {
        text: "We have partnered with multinational banks, card processors and giant IT companies over the past 20 years.",
      },
      {
        text: " We have a team of dedicated software engineers in developing innovative applications for the banking, finance, medical & manufacturing industries.",
      },
    ],
    listItems: [
      {
        heading: "VisionPlus Product Services ",
        para: " Finsol is globally recognized with a range of innovative products and services, including VisionPlus support.",
      },
      {
        heading: "Application services",
        para: "Our services are tailored to your specific needs. We focus more on understanding the client’s business goals.",
      },
      {
        heading: "Testing Services ",
        para: "Finsol’s software testing services, you can uncover software bugs before your clients do. We rigorously test our applications, using detailed testing measures.",
      },
    ],
    sectionImage: images.about.about_img_1,
  },
  tcoeSection:{
    sectionHeading: "TCoE Offerings",
    sectionPara:" The Finsol consultancy's Quality division assists innovators in achieving corporate objectives and maintaining high quality. Let us understand your thoughts, and we will assist you in determining the qualities that are essential to your success.",
    sectionImage: images.about.tcoe_img,

  },
  whatWeServe: {
    sectionHeading: "What We Serve",
    sectionPara:
      " Application Solutions to Make Your Business Shine!We offer a wide range of services for you to choose from Our comprehensive range of services includes.",
    sectionListItems: [
      {
        text: "Card and Payment processing applications.",
      },
      {
        text: "Healthcare ERP solutions.",
      },
      {
        text: "Mobile Application Development.",
      },
      {
        text: "Application Development in Banking, Finance domains.",
      },
      {
        text: "Migration to new Systems.",
      },
    ],
    sectionImage: images.about.about_img_2,
    section1: {
      sectionImage: images.about.about_img_3,
      sectionpara:
        " We offer a variety of services that make it easy for you to get started with our intuitive platform and help you to transform and scale your business. If you have any questions,please feel free to get in touch with us at info@finsolconsultancy. com or call us at +91 891 2762072 to learn more about our offerings.",
    },
  },
  whomWeServe: {
    sectionListItems: [
      {
        heading: "Non-IT enterprises",
        para: " Finsol Services improve business performance, optimize customer service, and tap in digital transformation. ",
      },
      {
        heading: "Software product companies",
        para: "Finsol Services help bring new products to the market and win customers fast We are honoured to serve some of the large and diverse group of customers.",
      },
      {
        heading: "Quality Industrial Services",
        para: "Finsol has to provide top-quality industrial services. We are experts in a range of domains and provide products and services in several verticals such as Banking, Finance, Healthcare, clinical trials, and Manufacturing.",
      },
    ],
    sectionImage: images.about.about_img_4,
    listItems: [
      {
        heading: "IT Consulting",
        para: " Expert assistance by Finsol in achieving the goals of IT modernization, IT infrastructure optimization and ongoing management, cloud migration.  ",
      },
      {
        heading: "Software Development",
        para: " Finsol provide Design, development, and support of enterprise software web and mobile applications, cloud development.",
      },
      {
        heading: "Data Analytics",
        para: "Finsol’s Design and implementation of data analytics solutions are unique. Finsol has a high-end Data management system.",
      },
      {
        heading: "Infrastructure services",
        para: " Finsol thrives for optimization of IT infrastructure for reduced IT spending, improved reliability, and operational improvements.",
      },
    ],
    sectionImage1: "",
  },
  testimonial: [
    {
      content:
        "Finsol promotes an atmosphere that embraces continual learning at both the industrial and technological levels.",
      authorname: " ",
      position: " ",
    },
    {
      content:
        "I enjoy working for Finsol because the work atmosphere is fantastic, like a home away from home.",
      authorname: " ",
      position: " ",
    },
    {
      content:
        "The fact that each day I am faced with a new opportunity to learn and grow is one reason I love working at Finsol.",
      authorname: " ",
      position: " ",
    },
  ],
};

export default about;
