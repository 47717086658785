import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import aboutdata from "../../../common/data/about";
import InnerBannerSection from "../../utility/innerPageBanner";


function About() {
  const data = aboutdata;
  return (
    <div>
      <InnerBannerSection
        text1={"About Us"}
        text2={""}
        currentPage={"About us"}
        image={""}
        imageText={""}
        type={"center"}
      />
      <div className="fancy-feature-two position-relative  section-space ">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <ScrollAnimation animateIn="animate__fadeIn">
                <img
                  src={data.about_company.sectionImage}
                  alt={data.sectionImageText}
                  className="transform-img-meta"
                />
              </ScrollAnimation>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2  ms-auto">
              <div className="block-style-two">
                <div className="title-style-one">
                  {/*<div className="sc-title-four">WHO WE ARE</div>*/}
                  <ScrollAnimation animateIn="animate__fadeIn">
                    {data.about_company.sectionHeading && (
                      <h2 className="main-title ">
                        {data.about_company.sectionHeading}
                      </h2>
                    )}
                  </ScrollAnimation>
                </div>
                <ScrollAnimation animateIn="animate__fadeIn">
                  {data.about_company.sectionPara && (
                    <p className="pt-10 pb-10">
                      {data.about_company.sectionPara}{" "}
                    </p>
                  )}
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeIn">
                  {data.about_company.sectionListItems && (
                    <ul className="style-none list-item color-rev">
                      {data.about_company.sectionListItems.map(
                        (items, index) => (
                          <li key={index + 1}>{items.text}</li>
                        )
                      )}
                    </ul>
                  )}
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="2"
        className="fancy-feature-two position-relative section-space "
      >
        <div className="container">
          <div className="row">
            <div className=" col-12 col-lg-6 ms-auto">
              <div className="block-style-two">
                <div className="title-style-one">
                  <div className="sc-title-four">WHO WE ARE</div>
                  <ScrollAnimation animateIn="animate__fadeIn">
                    {data.whatWeServe.sectionHeading && (
                      <h2 className="main-title mt-4">
                        {data.whatWeServe.sectionHeading}
                      </h2>
                    )}
                  </ScrollAnimation>
                </div>
                <ScrollAnimation animateIn="animate__fadeIn">
                  {data.whatWeServe.sectionPara && (
                    <p className="pt-10 pb-10">
                      {data.whatWeServe.sectionPara}
                    </p>
                  )}
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeIn">
                  {data.whatWeServe.sectionListItems && (
                    <ul className="style-none list-item color-rev">
                      {data.whatWeServe.sectionListItems.map((items, index) => (
                        <li key={index + 1}>{items.text}</li>
                      ))}
                    </ul>
                  )}
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-12 col-lg-6 ">
              <ScrollAnimation animateIn="animate__fadeIn">
                <img
                  src={data.whatWeServe.sectionImage}
                  alt={data.sectionImageText}
                  className="transform-img-meta margin "
                />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="section-space ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 order-md-last">
              <div className="block-style-nine ps-lg-5 ms-xxl-3">
                <div className="title-style-three">
                  <div className="sc-title">Contact us</div>
                  <h2 className="main-title">
                    Get Started <span>With</span> Finsol Today !
                  </h2>
                </div>
                <p className="pt-20 pb-30" data-aos-delay="80">
                 {data.whatWeServe.section1.sectionpara}
                </p>
                <a
                  href="contact-us.html"
                  className="btn-eight "
                  data-aos-delay="130"
                >
                  Send Message
                </a>
              </div>
            </div>
            <div className="col-12 col-sm-5 order-md-first ">
              
              <img
                src={data.whatWeServe.section1.sectionImage}
                alt={data.sectionImageText}
                className="transform-img-meta margin "
              />
              
            </div>
          </div>
        </div>
                    </div>*/}
      {/*<h1>Whom we serve</h1> */}

      <div>
        <div className="feedback-section-five section-space">
          <div className="container">
            <div>
            <div className="title-style-three text-center">
              <div className="sc-title">Quality</div>
              <h2 className="main-title">{data.tcoeSection.sectionHeading}</h2>
              <p className="pt-20 pb-30" data-aos-delay="80">
                {data.tcoeSection.sectionPara}
              </p>
            </div>
            <div>
            <ScrollAnimation animateIn="animate__fadeIn">
                <img
                  src={data.tcoeSection.sectionImage}
                  alt={data.sectionImageText}
                  className=""
                />
              </ScrollAnimation>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fancy-feature-nineteen position-relative  section-space">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="block-style-thirteen">
                <div className="title-style-three ">
                  <div className="sc-title">WORK PROCESS</div>
                  <ScrollAnimation animateIn="animate__fadeIn">
                    <h2 className="main-title">
                      Whom We <span>Serve</span>{" "}
                    </h2>
                  </ScrollAnimation>
                </div>
                <ScrollAnimation animateIn="animate__fadeIn">
                  {data.whomWeServe.sectionListItems && (
                    <ul className="style-none list-item">
                      {data.whomWeServe.sectionListItems.map((items, index) => (
                        <li key={index + 1}>
                          <div className="numb tran3s">{index + 1}</div>
                          <h6>{items.heading}</h6>
                          <span>{items.para}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <ScrollAnimation animateIn="animate__fadeIn">
                <img
                  src={data.whomWeServe.sectionImage}
                  alt={data.sectionImageText}
                  className="transform-img-meta margin "
                />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>

      <div className="fancy-feature-nineteen position-relative section-space">
        <div className="container">
          <div className="block-style-thirteen">
            <ScrollAnimation animateIn="animate__fadeIn">
              <ul className="list-item row list-unstyled">
                {data.whomWeServe.listItems &&
                  data.whomWeServe.listItems.map((items, index) => (
                    <li key={index + 1} className="col-12 col-sm-6">
                      <p className="numb tran3s">{index + 1}</p>
                      {items.heading && <h6>{items.heading}</h6>}
                      {items.para && <span>{items.para}</span>}
                    </li>
                  ))}
              </ul>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="feedback-section-three position-relative content-center section-space">
        <div className="container">
          <div className="">
            <Carousel
              autoPlay={true}
              stopOnHover={true}
              infiniteLoop={true}
              showThumbs={false}
            >
              {data.testimonial &&
                data.testimonial.map((data, index) => (
                  <div
                    key={data.id}
                    className="feedback-block-four mb-20 ms-xxl-4 me-xxl-4"
                  >
                    {data.content && (
                      <p className="fs-4 mr-0">{data.content}</p>
                    )}
                    <div className="mt-3">
                      {data.authorname && <h6>{data.authorname}</h6>}
                      {data.position && <span>{data.position}</span>}
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
