import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import Home from "../../../../common/data/home";
function ContactSection() {
  const data = Home.contactSection;
  return (
    <div className="section-space ">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7 order-md-last">
            <div className="block-style-nine ps-lg-5 ms-xxl-3">
              <div className="title-style-three">
                <div className="sc-title">Contact us</div>
                <h2 className="main-title">
                  Get Started <span>With</span> Finsol Today !
                </h2>
              </div>
              <p className="pt-20 pb-30" data-aos-delay="80">
                {data.sectionpara}
              </p>
              <Link to="/contact" className="btn-eight " data-aos-delay="130">
                Send Message
              </Link>
            </div>
          </div>
          <div className="col-12 col-sm-5 order-md-first ">
            <ScrollAnimation animateIn="animate__fadeIn">
              <img
                src={data.sectionImage}
                alt=""
                className="transform-img-meta margin "
              />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
