import emailjs from "@emailjs/browser";
import React, { useLayoutEffect, useRef, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import carrersData from "../../../common/data/carrers";
import InnerBannerSection from "../../utility/innerPageBanner";


function CareerPage() {
  const formRef = useRef();
  const fileInputRef = useRef();
  const [success, setSuccess] = useState(false);


  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const response = await emailjs.sendForm(
        "service_ugb811d",
        "template_h075pqn",
        e.target,
        "olQxEavQJrdJgx4gm"
      );
      console.log("Email sent:", response);
      setSuccess(true);
    } catch (error) {
      console.error("Error sending email:", error);
      setSuccess(false);
    }
  };
  

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const data = carrersData;
  return (
    <>
      <InnerBannerSection
        text1={data.innerBanner.text1}
        text2={data.innerBanner.text2}
        currentPage={data.currentPage}
        image={data.innerBanner.image}
        imageText={data.innerBanner.imageText}
        type={data.innerBanner.type}
      />
      <section className="fancy-feature-twelve section-space">
        <div className="container">
          <div className="row align-items-center align-items-xl-start">
            <div className="col-xl-12 col-md-6 order-md-last">
              <div className="block-style-nine color-two">
                <div className="title-style-three pb-10">
                  <ScrollAnimation animateIn="animate__fadeIn" initiallyVisible>
                    <div className="sc-title">{data.section1.screenTitle}</div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="animate__fadeIn" initiallyVisible>
                    <h2 className="main-title">{data.section1.sectionTitle}</h2>
                  </ScrollAnimation>
                </div>
                <ScrollAnimation animateIn="animate__fadeIn" initiallyVisible>
                  {data.section1.sectionPara && (
                    <p>{data.section1.sectionPara}</p>
                  )}
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>
      {data.jobCardSection && (
        <section className="job-card">
          <div className="container">
            <Tabs className="d-flex">
              <TabList className="d-flex flex-column list-unstyled job-card-tabs">
                {data.jobCardSection.tabList.map((items) => (
                  <Tab key={items.id}>
                    <div className="d-flex p-2">
                      <div className="me-2">
                        <div style={{ width: 50, height: 50 }}>
                          <ScrollAnimation animateIn="animate__fadeIn">
                            <img
                              src={items.icon}
                              alt="any image"
                              width="50px"
                              height="50px"
                            />
                          </ScrollAnimation>
                        </div>
                      </div>
                      <div>
                        <h6 className="fs-16 m-0">{items.jobTitle}</h6>
                        <ul className="list-unstyled fs-14">
                          <li>{items.experience}</li>
                          <li>{items.location}</li>
                          <li>{items.postedDate}</li>
                        </ul>
                      </div>
                    </div>
                  </Tab>
                ))}
              </TabList>
              {data.jobCardSection.tabList.map((items) => (
                <TabPanel key={items.key}>
                  <div className="card-content p-3 fs-16">
                    <div className="ms-4">
                      <h2 className="fs-32 fw-normal">{items.jobTitle}</h2>
                      <p className="m-0 mt-2">{items.location}</p>
                      <div className="d-flex">
                        <svg
                          viewBox="0 0 24 24"
                          data-supported-dps="24x24"
                          fill="var(--prime-three)"
                          className="me-2 mt-2"
                          width="24"
                          height="24"
                          focusable="false"
                        >
                          <path d="M17 6V5a3 3 0 00-3-3h-4a3 3 0 00-3 3v1H2v4a3 3 0 003 3h14a3 3 0 003-3V6zM9 5a1 1 0 011-1h4a1 1 0 011 1v1H9zm10 9a4 4 0 003-1.38V17a3 3 0 01-3 3H5a3 3 0 01-3-3v-4.38A4 4 0 005 14z"></path>
                        </svg>
                        <p className="m-0 mt-2">{items.shift}</p>
                      </div>

                      <div className="d-flex">
                        <svg
                          viewBox="0 0 24 24"
                          data-supported-dps="24x24"
                          fill="var(--prime-three)"
                          className="me-2 mt-2"
                          width="24"
                          height="24"
                          focusable="false"
                        >
                          <path d="M4 2v20h16V2zm14 18h-4v-2h-4v2H6V4h12zm-7-8H8v-2h3zm0 4H8v-2h3zm5-4h-3v-2h3zm-5-4H8V6h3zm5 0h-3V6h3zm0 8h-3v-2h3z"></path>
                        </svg>
                        <p className="m-0 mt-2">{items.qualification}</p>
                      </div>

                      <div className="d-flex">
                        <svg
                          viewBox="0 0 24 24"
                          data-supported-dps="24x24"
                          fill="var(--prime-three)"
                          className="me-2 mt-2"
                          width="24"
                          height="24"
                          focusable="false"
                        >
                          <path d="M12 20a8 8 0 010-16 7.91 7.91 0 014.9 1.69l-1.43 1.42a6 6 0 101.42 1.42l3.82-3.82a1 1 0 000-1.41A1 1 0 0020 3a1 1 0 00-.7.29l-1 1A10 10 0 1022 12h-2a8 8 0 01-8 8zm5-8a5 5 0 11-5-5 4.93 4.93 0 012.76.82l-2.24 2.24A2.24 2.24 0 0012 10a2 2 0 102 2 2.24 2.24 0 00-.07-.51l2.24-2.24A5 5 0 0117 12z"></path>
                        </svg>
                        <p className="m-0 mt-2">{items.location}</p>
                      </div>
                    </div>

                    <div className="career-main-page-job-details mt-4 ms-4">
                      <h4 className="mb-3">{items.summary.title}</h4>
                      {items.summary.description &&
                        items.summary.description.map((source) => (
                          <div>
                            <h6 className="text-uppercase">{source.title}</h6>
                            <ul>
                              {source.list.map((li, index) => (
                                <li key={index + 1}>{li}</li>
                              ))}
                            </ul>
                          </div>
                        ))}

                      {items.summary.skills && (
                        <div>
                          <h6 className="text-uppercase">
                            {items.summary.skills.title}
                          </h6>
                          <ul className="d-flex list-unstyled">
                            {items.summary.skills.list.map((li, index) => (
                              <li key={index + 1}>{li}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </TabPanel>
              ))}
            </Tabs>
          </div>
        </section>
      )}

      <section className="container section-space">
        <div className="row gx-xxl-5">
          <div className="col-lg-6 d-flex order-lg-last">
            <div className="form-style-one">
              <h3 className="form-title pb-40 lg-pb-20">Send Resume.</h3>
              <form
                ref={formRef}
                onSubmit={sendEmail}
                encType="multipart/form-data"
                method="post"
                id="contact-form"
                data-toggle="validator"
              >
                <div className="messages"></div>
                <div className="row controls">
                  <div className="col-12">
                    <div className="input-group-meta form-group mb-30">
                      <label>Name*</label>
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        name="user_name"
                        required="required"
                        data-error="Name is required."
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="input-group-meta form-group mb-30">
                      <label>Email*</label>
                      <input
                        type="email"
                        placeholder="Enter your Email ID"
                        name="user_email"
                        required="required"
                        data-error="Valid email is required."
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="input-group-meta form-group mb-30">
                      <label>Phonenumber*</label>
                      <input
                        type="text"
                        maxLength={10}
                        placeholder="Enter your Phone number"
                        name="user_phone"
                        required="required"
                        data-error="Valid Phone number is required."
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  {/* <div className ="col-12">
                      <div className="input-group-meta form-group mb-30">
                        <label>Upload your Resume*</label>
                        <input
                          ref={fileInputRef}
                          className="form-control"
                          id="formFileSm"
                          name="user_resume"
                          type="file"
                          required
                        />
                        <div className="help-block with-errors"></div>
                      </div>  
                    </div> */}
                  <div className="col-12">
                    <div className="input-group-meta form-group mb-30">
                      <textarea
                        placeholder="Your message*"
                        name="message"
                        required="required"
                        data-error="Please,leave us a message."
                      ></textarea>
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn-eight ripple-btn">
                      Send Resume
                    </button>
                 </div>         
                </div>
                {success && 
                    <p className="alert alert-primary mt-4 ms-2" role="alert">
                       Sent Successfully.
                    </p>
                 }
              </form>
            </div>
          </div>
          <div className="col-lg-6 d-flex order-lg-first">
            {/*<ScrollAnimation animateIn="animate__bounceIn"> */}
            <img src={data.section1.image} alt="" />
            {/* </ScrollAnimation> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default CareerPage;
