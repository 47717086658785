import darkLogo from "../../assets/images/logo/main_logo.svg";

import shape_25 from "../../assets/images/shape/shape_25.svg";
import shape_38 from "../../assets/images/shape/shape_38.svg";
import shape_39 from "../../assets/images/shape/shape_39.svg";
import shape_48 from "../../assets/images/shape/shape_48.svg";

import icon_17 from "../../assets/images/icon/icon_17.svg";
import icon_18 from "../../assets/images/icon/icon_18.svg";
import icon_19 from "../../assets/images/icon/icon_19.svg";
import icon_20 from "../../assets/images/icon/icon_20.svg";
import icon_31 from "../../assets/images/icon/icon_31.svg";
import icon_32 from "../../assets/images/icon/icon_32.svg";
import icon_33 from "../../assets/images/icon/icon_33.svg";
import icon_34 from "../../assets/images/icon/icon_34.svg";
import icon_37 from "../../assets/images/icon/icon_37.svg";
import icon_38 from "../../assets/images/icon/icon_38.svg";
import icon_39 from "../../assets/images/icon/icon_39.svg";
import icon_41 from "../../assets/images/icon/icon_41.svg";
import icon_42 from "../../assets/images/icon/icon_42.svg";
import icon_43 from "../../assets/images/icon/icon_43.svg";
import location from "../../assets/images/icon/contact/location.svg";
import email from "../../assets/images/icon/contact/email.svg";
import call from "../../assets/images/icon/contact/call.svg";
import banner_img_1 from "../../assets/images/home/bannerimg_1.svg";
import home_img_2 from "../../assets/images/home/home_img_2.svg";
import home_img_3 from "../../assets/images/home/home_img_3.svg";
import home_img_4 from "../../assets/images/home/home_img_4.svg";

import icon_1 from "../../assets/images/home/icon_1.svg";
import icon_2 from "../../assets/images/home/icon_2.svg";
import icon_3 from "../../assets/images/home/icon_3.svg";
import icon_4 from "../../assets/images/home/icon_4.svg";
import icon_5 from "../../assets/images/home/icon_5.svg";
import icon_6 from "../../assets/images/home/icon_6.svg";

import about_img_1 from "../../assets/images/about/about_img_1.svg";
import about_img_2 from "../../assets/images/about/about_img_2.svg";
import about_img_3 from "../../assets/images/about/about_img_3.svg";
import about_img_4 from "../../assets/images/about/about_img_4.svg";
import tcoe_img from "../../assets/images/about/tcoe_img.png";

import carrer_img from "../../../common/assets/images/carrer/carrer_img.svg";

import banking_img_1 from "../../assets/images/industry/banking/banking_img_1.svg";
import banking_img_2 from "../../assets/images/industry/banking/banking_img_2.svg";
import banking_img_3 from "../../assets/images/industry/banking/banking_img_3.svg";
import banking_img_4 from "../../assets/images/industry/banking/banking_img_4.svg";

import health_img_1 from "../../assets/images/industry/health/health_img_1.svg";
import health_img_2 from "../../assets/images/industry/health/health_img_2.svg";
import health_img_3 from "../../assets/images/industry/health/health_img_3.svg";
import health_img_4 from "../../assets/images/industry/health/health_img_4.svg";

import crm_img_1 from "../../assets/images/product/crm/crm_img_1.svg";
import crm_img_2 from "../../assets/images/product/crm/crm_img_2.svg";
import crm_img_3 from "../../assets/images/product/crm/crm_img_3.svg";
import crm_icon_1 from "../../assets/images/product/crm/crm_icon_1.svg";
import crm_icon_2 from "../../assets/images/product/crm/crm_icon_2.svg";
import crm_icon_3 from "../../assets/images/product/crm/crm_icon_3.svg";

import erp_img_1 from "../../assets/images/product/health/erp_img_1.svg";
import erp_img_2 from "../../assets/images/product/health/erp_img_2.svg";

import sugar_img_1 from "../../assets/images/product/sugar/sugar_img_1.svg";
import sugar_img_2 from "../../assets/images/product/sugar/sugar_img_2.svg";
import sugar_img_3 from "../../assets/images/product/sugar/sugar_img_3.svg";

import adimg from '../../assets/images/Ads/ad-img.svg';
import adlogo from '../../assets/images/Ads/ad-logo.png';

import applicationdevelopment1 from "../../assets/images/service/application_development/application_1.svg";
import applicationdevelopment2 from "../../assets/images/service/application_development/application_2.webp";
import applicationdevelopment3 from "../../assets/images/service/application_development/application_3.webp";
import applicationdevelopment4 from "../../assets/images/service/application_development/application_4.svg";

import it_img_1 from "../../assets/images/service/it_cloud/it_img_1.svg";
import it_img_2 from "../../assets/images/service/it_cloud/it_img_2.svg";
import it_img_3 from "../../assets/images/service/it_cloud/it_img_3.svg";
import it_img_4 from "../../assets/images/service/it_cloud/it_img_4.svg";

import project_management_1 from "../../assets/images/service/project_management/projectmanagement_1.svg";
import project_management_2 from "../../assets/images/service/project_management/projectmanagement_2.svg";
import project_management_3 from "../../assets/images/service/project_management/projectmanagement_3.svg";

import migration_card_1 from "../../assets/images/service/migration_cards/migration_card_1.svg";
import migration_card_2 from "../../assets/images/service/migration_cards/migration_card_2.svg";
import migration_card_3 from "../../assets/images/service/migration_cards/migration_card_3.svg";

import production_support_1 from "../../assets/images/service/production_support/production_support_1.svg";
import production_support_2 from "../../assets/images/service/production_support/production_support_2.svg";
import production_support_3 from "../../assets/images/service/production_support/production_support_3.svg";
import production_support_4 from "../../assets/images/service/production_support/production_support_4.svg";

import quality_assurance_1 from "../../assets/images/service/quality_assurance/quality_assurance_1.svg";
import quality_assurance_2 from "../../assets/images/service/quality_assurance/quality_assurance_2.svg";

import business_1 from "../../assets/images/service/business/business_1.svg";
import business_2 from "../../assets/images/service/business/business_2.svg";
import business_3 from "../../assets/images/service/business/business_3.svg";
import business_4 from "../../assets/images/service/business/business_4.svg";

import b2b_project_1 from "../../assets/images/service/b2b_project/b2b_project_1.svg";
import b2b_project_2 from "../../assets/images/service/b2b_project/b2b_project_2.svg";
import b2b_project_3 from "../../assets/images/service/b2b_project/b2b_project_3.svg";

import resource_augmentation_1 from "../../assets/images/service/resource_augmentation/resource_augmentation_1.svg";
import resource_augmentation_2 from "../../assets/images/service/resource_augmentation/resource_augmentation_2.svg";
import resource_augmentation_3 from "../../assets/images/service/resource_augmentation/resource_augmentation_3.svg";
import resource_augmentation_4 from "../../assets/images/service/resource_augmentation/resource_augmentation_4.svg";

import ui_img_1 from "../../assets/images/service/ui/ui_img_1.svg";
import ui_img_2 from "../../assets/images/service/ui/ui_img_2.svg";
import ui_img_3 from "../../assets/images/service/ui/ui_img_3.svg";

import idea1 from "../../assets/images/assets/idea1.jpg";
import idea2 from "../../assets/images/assets/idea2.jpg";

import projectmanagement from "../../assets/images/assets/project-management.jpg";
import projectmanagement1 from "../../assets/images/assets/project-management1.jpg";
import projectmanagement2 from "../../assets/images/assets/project-management2.jpg";
import projectmanagement3 from "../../assets/images/assets/project-management3.jpg";

import resource1 from "../../assets/images/assets/resource1.jpg";
import resource2 from "../../assets/images/assets/resource2.jpg";

import carrers from "../../assets/images/assets/carrers.svg";
import ils_14 from "../../assets/images/assets/ils_14.svg";
import ils_14_1 from "../../assets/images/assets/ils_14_1.svg";
import ils_14_2 from "../../assets/images/assets/ils_14_2.svg";
import ils_14_3 from "../../assets/images/assets/ils_14_3.svg";
import ils_14_4 from "../../assets/images/assets/ils_14_4.svg";
import ils_14_5 from "../../assets/images/assets/ils_14_5.svg";
import ils_14_6 from "../../assets/images/assets/ils_14_6.svg";
import ils_14_7 from "../../assets/images/assets/ils_14_7.svg";
import ils_18 from "../../assets/images/assets/ils_18.svg";
import ils_20 from "../../assets/images/assets/ils_20.svg";

import Plogo_12 from "../../assets/images/logo/Plogo-12.png";
import Plogo_13 from "../../assets/images/logo/Plogo-13.png";
import Plogo_07 from "../../assets/images/logo/Plogo-7.png";
import Plogo_08 from "../../assets/images/logo/Plogo-8.png";
import Plogo_09 from "../../assets/images/logo/Plogo-9.png";

import blog_img_01 from "../../assets/images/blog/blog_img_01.jpg";

import img_13 from "../../assets/images/media/img_13.jpg";
import pic_1 from "../../assets/images/media/pic_1.jpg";
import pic_2 from "../../assets/images/media/pic_2.jpg";
import migrationcard1 from "../../assets/images/media/migration-card1.jpg";
import migrationcard2 from "../../assets/images/media/migration-card2.jpg";
import migrationcard3 from "../../assets/images/media/migration-card3.jpg";

const Images = {
  darkLogo: darkLogo,
  home: {
    banner_img_1: banner_img_1,
    home_img_2: home_img_2,
    home_img_3: home_img_3,
    home_img_4: home_img_4,
    icon_1: icon_1,
    icon_2: icon_2,
    icon_3: icon_3,
    icon_4: icon_4,
    icon_5: icon_5,
    icon_6: icon_6,
  },
  about: {
    about_img_1: about_img_1,
    about_img_2: about_img_2,
    about_img_3: about_img_3,
    about_img_4: about_img_4,
    tcoe_img:tcoe_img
  },
  popup:{
    adimg:adimg,
    adlogo:adlogo
  },
  contact: {
    location: location,
    email: email,
    call: call,
  },
  carrer: {
    carrer_img: carrer_img,
  },
  banking: {
    banking_img_1: banking_img_1,
    banking_img_2: banking_img_2,
    banking_img_3: banking_img_3,
    banking_img_4: banking_img_4,
  },
  healthcare: {
    health_img_1: health_img_1,
    health_img_2: health_img_2,
    health_img_3: health_img_3,
    health_img_4: health_img_4,
  },
  crm: {
    crm_img_1: crm_img_1,
    crm_img_2: crm_img_2,
    crm_img_3: crm_img_3,
    crm_icon_1: crm_icon_1,
    crm_icon_2: crm_icon_2,
    crm_icon_3: crm_icon_3,
  },
  health: {
    erp_img_1: erp_img_1,
    erp_img_2: erp_img_2,
  },
  sugar: {
    sugar_img_1: sugar_img_1,
    sugar_img_2: sugar_img_2,
    sugar_img_3: sugar_img_3,
  },
  application_development: {
    applicationdevelopment1: applicationdevelopment1,
    applicationdevelopment2: applicationdevelopment2,
    applicationdevelopment3: applicationdevelopment3,
    applicationdevelopment4: applicationdevelopment4,
  },
  it_cloud: {
    it_img_1: it_img_1,
    it_img_2: it_img_2,
    it_img_3: it_img_3,
    it_img_4: it_img_4,
  },
  project_management: {
    project_management_1: project_management_1,
    project_management_2: project_management_2,
    project_management_3: project_management_3,
  },
  migration_card: {
    migration_card_1: migration_card_1,
    migration_card_2: migration_card_2,
    migration_card_3: migration_card_3,
  },
  production_support: {
    production_support_1: production_support_1,
    production_support_2: production_support_2,
    production_support_3: production_support_3,
    production_support_4: production_support_4,
  },
  quality_assurance: {
    quality_assurance_1: quality_assurance_1,
    quality_assurance_2: quality_assurance_2,
  },
  business: {
    business_1: business_1,
    business_2: business_2,
    business_3: business_3,
    business_4: business_4,
  },
  b2b_project: {
    b2b_project_1: b2b_project_1,
    b2b_project_2: b2b_project_2,
    b2b_project_3: b2b_project_3,
  },
  resource_augmentation: {
    resource_augmentation_1: resource_augmentation_1,
    resource_augmentation_2: resource_augmentation_2,
    resource_augmentation_3: resource_augmentation_3,
    resource_augmentation_4: resource_augmentation_4,
  },
  ui_ux: {
    ui_img_1: ui_img_1,
    ui_img_2: ui_img_2,
    ui_img_3: ui_img_3,
  },
  icon: {
    icon_17: icon_17,
    icon_18: icon_18,
    icon_19: icon_19,
    icon_20: icon_20,
    icon_31: icon_31,
    icon_32: icon_32,
    icon_33: icon_33,
    icon_34: icon_34,
    icon_37: icon_37,
    icon_38: icon_38,
    icon_39: icon_39,
    icon_41: icon_41,
    icon_42: icon_42,
    icon_43: icon_43,
  },
  shape: {
    shape_38: shape_38,
    shape_39: shape_39,
    shape_25: shape_25,
    shape_48: shape_48,
  },
  assets: {
    ils_18: ils_18,
    ils_20: ils_20,
    ils_14: ils_14,
    ils_14_1: ils_14_1,
    ils_14_2: ils_14_2,
    ils_14_3: ils_14_3,
    ils_14_4: ils_14_4,
    ils_14_5: ils_14_5,
    ils_14_6: ils_14_6,
    ils_14_7: ils_14_7,

    resource1: resource1,
    resource2: resource2,
    carrers: carrers,

    idea1: idea1,
    idea2: idea2,
    projectmanagement: projectmanagement,
    projectmanagement1: projectmanagement1,
    projectmanagement2: projectmanagement2,
    projectmanagement3: projectmanagement3,
  },
  blog: {
    blog_img_01: blog_img_01,
  },
  media: {
    img_13: img_13,
    pic_1: pic_1,
    pic_2: pic_2,
    migrationcard1: migrationcard1,
    migrationcard2: migrationcard2,
    migrationcard3: migrationcard3,
  },
  partner: {
    Plogo_07: Plogo_07,
    Plogo_08: Plogo_08,
    Plogo_09: Plogo_09,
    Plogo_12: Plogo_12,
    Plogo_13: Plogo_13,
  },
};
export default Images;
