import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import img_08 from "../../../../common/assets/images/media/img_08.jpg";
import img_09 from "../../../../common/assets/images/media/img_09.jpg";
import img_10 from "../../../../common/assets/images/media/img_10.jpg";
import img_11 from "../../../../common/assets/images/media/img_11.jpg";
import Home from "../../../../common/data/home";

function TestimonailSection() {
  const data = Home.TestimonialSection;
  return (
    <div className="feedback-section-five section-space">
      <div className="container">
        <div className="title-style-three text-center">
          <div className="sc-title">{data.screenTitle}</div>
          <h2 className="main-title">{data.mainTitle}</h2>
        </div>
        <div className="">
          <Carousel
            autoPlay={true}
            stopOnHover={true}
            infiniteLoop={true}
            showThumbs={false}
          >
            {data.testimonialsList.map((items) => (
              <div
                key={items.id}
                className="feedback-block-four mb-20 ms-xxl-4 me-xxl-4"
              >
                <div>
                  <img src={items.image} alt="" className="m-auto" />
                </div>
                <p>{items.text}</p>
                <div className="cp-info">
                  <h6>{items.author}</h6>
                  <span>{items.designation}</span>
                </div>
              </div>
            ))}
          </Carousel>
        </div>

        <div className="feedback_slider_four"></div>
      </div>
      {/* <img
        src={img_08}
        alt=""
        className="shapes avatar-one"
        width="45"
        height="45"
        // style={{"outline-width: 6px;"}}
      />
      <img
        src={img_09}
        alt=""
        className="shapes avatar-two"
        width="85"
        height="85"
        // style="outline-width: 10px;"
      />
      <img
        src={img_10}
        alt=""
        className="shapes avatar-three"
        width="85"
        height="85"
        // style="outline-width: 10px;"
      />
      <img
        src={img_11}
        alt=""
        className="shapes avatar-four"
        width="50"
        height="50"
        // style="outline-width: 5px;"
      /> */}
    </div>
  );
}

export default TestimonailSection;
