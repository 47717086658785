import React from "react";
import { Link, NavLink } from "react-router-dom";
import Home from "../../../common/data/home";

function Navbar() {
  return (
    <header className="theme-main-menu sticky-menu theme-menu-four fixed">
      <div className="inner-content">
        <div className="d-flex align-items-center">
          <div className="logo order-lg-0">
            <Link to="/">
              <img src={Home.mainLogo} alt="" width="90" />
            </Link>
          </div>
          <div className="right-widget d-flex align-items-center ms-auto order-lg-3">
            <div className="call-button d-none d-xl-block me-3">
              Call us : {Home.companyContact}
            </div>
            <Link
              to="/contact"
              className="send-msg-btn tran3s d-none d-lg-block"
            >
              {Home.buttonText}
            </Link>
          </div>

          <nav className="navbar navbar-expand-lg order-lg-2">
            <button
              className="navbar-toggler d-block d-lg-none"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="d-block d-lg-none">
                  <div className="logo">
                    <NavLink to="/">
                      <img src={Home.mainLogo} alt="" width="90" />
                    </NavLink>
                  </div>
                </li>
                {Home.menuLinks.length > 0 &&
                  Home.menuLinks.map((menuitems, index) => (
                    <li key={index + 1} className="nav-item dropdown">
                      {menuitems.subMenu ? (
                        <>
                          <span
                            role="button"
                            className="nav-link dropdown-toggle pointer"
                            data-bs-toggle="collapse"
                            data-bs-target="#submenu"
                            aria-controls="submenu"
                            aria-expanded="false"
                          >
                            {menuitems.link}
                          </span>
                          <div
                            className="dropdown-menu custom-navbar"
                            id="submenu"
                          >
                            <div className="row">
                              {menuitems.subMenu.map((submenuItems, index) => (
                                <div
                                  key={index + 1}
                                  className={`col-lg-${menuitems.colSize} ${
                                    menuitems.colSize === 12 ? "small" : "large"
                                  }`}
                                >
                                  <Link
                                    to={submenuItems.url}
                                    className="dropdown-item"
                                  >
                                    <span
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarNav"
                                    aria-controls="navbarNav"
                                      className={`${
                                        submenuItems.aviableData
                                          ? "included"
                                          : "not-included"
                                      }`}
                                    >
                                      {submenuItems.link}
                                    </span>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        <NavLink
                          className={`nav-link dropdown-toggle`}
                          to={menuitems.url}
                          role="button"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          {menuitems.link}
                        </NavLink>
                      )}
                    </li>
                  ))}
              </ul>
              <div className="mobile-content d-block d-lg-none">
                <div className="d-flex flex-column align-items-center justify-content-center mt-70">
                  <Link
                    to="/contact"
                    className="send-msg-btn tran3s d-none d-lg-block"
                  >
                    {Home.buttonText}
                  </Link>
                  <Link to="/contact" className="call-button">
                    {Home.buttonText}
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
