import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import industrydata from "../../../common/data/industry";
import InnerBannerSection from "../../utility/innerPageBanner";
import SectionOne from "../../utility/innerPageSections/sectionOne";
import SectionThree from "../../utility/innerPageSections/sectionThree";
import SectionTwo from "../../utility/innerPageSections/sectionTwo";

function IndustryPage() {
  const rawData = industrydata;
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="main-page-wrapper">
      {rawData.service.map((data) => (
        <div key={data.id}>
          {"/industry/" + data.id === location.pathname && (
            <InnerBannerSection
              text1={data.innerBanner.text1}
              text2={data.innerBanner.text2}
              currentPage={data.currentPage}
              image={data.innerBanner.image}
              imageText={data.innerBanner.imageText}
              type="left"
            />
          )}
          {"/industry/" + data.id === location.pathname && data.section1 && (
            <SectionOne data={data.section1} />
          )}
          {"/industry/" + data.id === location.pathname && data.section2 && (
            <SectionTwo data={data.section2} />
          )}
          {"/industry/" + data.id === location.pathname && data.section3 && (
            <SectionThree data={data.section3} />
          )}
        </div>
      ))}
    </div>
  );
}

export default IndustryPage;
