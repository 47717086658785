import React from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import Footer from "../footer";
import Navbar from "../navbar";
import Advertisement from "../advertisement";

const Layout = () => {
  return (
    <>
      <Navbar />
      {/* <Advertisement/> */}
      <Outlet />
      <Footer />
      <ScrollToTop smooth color="var(--prime-one)" width="20px" height="20px" />
    </>
  );
};

export default Layout;
