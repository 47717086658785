import Images from "../../data/images";

const Home = {
  mainLogo: Images.darkLogo,
  companyName: "Finsol Consultancy Private Limited",
  companyContact: "9885222922",
  mainLogoText: "Finsol Consutlancy Private Limited",
  buttonText: "Send a Message",
  menuLinks: [
    {
      link: "Home",
      url: "/",
      colSize: 12,
      subMenu: [
        {
          aviableData: true,
          link: "Services we Provide",
          url: "/",
        },
        {
          aviableData: true,
          link: "More about us",
          url: "/",
        },
        {
          aviableData: true,
          link: "Words from client",
          url: "/",
        },
      ],
    },
    {
      link: "About Us",
      url: "/about",
      colSize: 12,
      subMenu: [
        {
          aviableData: true,
          link: "About Company",
          url: "/about",
        },
        {
          aviableData: true,
          link: "Who We serve/Client",
          url: "/about",
        },
        {
          aviableData: true,
          link: "Testimonials",
          url: "/about",
        },
        // {
        //   aviableData: false,
        //   link: "Corporate social Responsibility",
        //   url: "/about",
        // },
        // {
        //   aviableData: false,
        //   link: "Management/Directors/Team",
        //   url: "/about",
        // },
      ],
    },
    {
      link: "Services",
      url: "",
      colSize: 6,
      subMenu: [
        {
          aviableData: true,
          link: "Application Development",
          url: "/services/application-development",
        },
        {
          aviableData: true,
          link: "Project Management Office",
          url: "/services/project-management-office",
        },
        {
          aviableData: true,
          link: "Business As Usual",
          url: "/services/business-as-usual",
        },
        {
          aviableData: true,
          link: "Production Support",
          url: "/services/production-support",
        },
        {
          aviableData: true,
          link: "IT Services & Cloud Solution",
          url: "/services/it-services-and-cloud-solution",
        },
        {
          aviableData: true,
          link: "Migration Projects in Cards & Payments",
          url: "/services/migration-projects",
        },
        // {
        //   aviableData: false,
        //   link: "Schemes Interaction",
        //   url: "/services/schemes-interaction",
        // },
        // {
        //   aviableData: false,
        //   link: "Third-party Processors",
        //   url: "/services/third-party-processors",
        // },
        {
          aviableData: true,
          link: "B2B Projects execution",
          url: "/services/b2b-projects-execution",
        },
        {
          aviableData: true,
          link: "Resource Augmentation",
          url: "/services/resource-augmentation",
        },
        {
          aviableData: true,
          link: "Quality Assurance",
          url: "/services/quality-assurance",
        },
        { aviableData: true, link: "UI/UX", url: "/services/ui-ux" },
      ],
    },
    {
      link: "Products",
      url: "",
      colSize: 12,
      subMenu: [
        {
          aviableData: true,
          link: "Health Care ERP",
          url: "/product/health-card-erp",
        },
        {
          aviableData: true,
          link: "Sugar Industry ERP",
          url: "/product/erp",
        },
        { aviableData: true, link: "CRM", url: "/product/crm" },
      ],
    },

    {
      link: "Industry",
      url: "",
      colSize: 12,
      subMenu: [
        {
          aviableData: true,
          link: "Banking & Finance",
          url: "/industry/banking-finance",
        },
        { aviableData: true, link: "Healthcare", url: "/industry/health-care" },

        // {
        //   aviableData: false,
        //   link: "Agriculture",
        //   url: "/industry/agriculture",
        // },

        // {
        //   aviableData: false,
        //   link: "Plastics Machinery",
        //   url: "/industry/plastic-machinery",
        // },
      ],
    },
    // {
    //   link: "Blog",
    //   url: "/blog",
    // },
    {
      link: "Careers",
      url: "/careers",
    },
    {
      link: "Contact",
      url: "/contact",
    },
  ],
  featureSectionEighteen: Images.home.home_img_2,
  featureSectionNineteen: Images.home.home_img_3,
  BannerSection: {
    bannerHeading: "Application Solutions to Make Your ",
    bannerHeadingHightlight: "Business Shine.",
    bannerPara:
      " We have a team of specialized & dedicated software engineers in developing innovative applications for the banking, finance, medical & manufacturing industries.",
    bannerbtntext: "Request A Demo",
    bannerImage: Images.home.bannerimg,
    bannerImage_1: Images.home.bannerimg_2,
    banner_img_1: Images.home.banner_img_1,
  },
  ProductSection: {
    title: "We offer a wide range of Services",
    subText:
      "With over 20+ years of helping customers accelerate digital transformation across the world, Finsol work with you to understand your digital customer journey.",
    productcard: [
      {
        id: 1,
        icon: Images.home.icon_1,
        heading: "Card and Payment processing applications",
        body: "Finsol helps clients in the payments sector by adopting global imperatives such as flexible and agile processing platforms, real-time payments, improved cross-border payments, effective merchant onboarding, loyalty, open banking APIs, and billing transformation. ",
        link: "/",
      },
      {
        id: 2,
        icon: Images.home.icon_2,
        heading: "Healthcare ERP solutions",
        body: "Finsol's Hospital Management Software is equipped with a rich and robust set of features that let you manage all the challenging work procedures in the healthcare sector with ease.It offers a user-friendly interface and handles essential operational tasks, such as financial accounting and patient services. ",
        link: "/",
      },
      {
        id: 3,
        icon: Images.home.icon_3,
        heading: "Application Development in Banking&Finance",
        body: "Finsol develops digital products and financial solutions that streamline financial management processes, increase financial data visibility, accuracy and security. We use trending and cutting-edge technologies to enable Enterprises to face the challenges that come ahead. ",
        link: "/",
      },
      {
        id: 4,
        icon: Images.home.icon_4,
        heading: "Software Re-Engineering for Manufacturing industries",
        body: "Finsol facilitates manufacturing enterprises to manage their manufacturing hubs, such as factories and plants, from the purchase to final product shipment. It automates every step of the production process, including inventory management, production scheduling, quality control, and raw material planning.",
        link: "/",
      },
      {
        id: 5,
        icon: Images.home.icon_5,
        heading: "Apps development",
        body: "Excellent for early-stage and small- to medium-sized firms seeking to verify their concepts and ascertain the market. We assist you in automating repetitive tasks and building web-based or mobile-based applications which help you in managing your entire business. We optimize the performance of your app to minimize costs and keep up the reputation of the business. ",
        link: "/",
      },
      {
        id: 6,
        icon: Images.home.icon_6,
        heading: "Testing Programs",
        body: "Finsol offers testing support with unrivalled coverage, speed, and quality as a leading provider of testing and QA services. Our testing programs can help you to improve the quality of your application, reduce the time spent on product verification, and raise the speed and quality of your products to improve user experience and generate more income.",
        link: "/",
      },
    ],
  },
  CounterSection: [
    {
      id: 1,
      count: 80,
      decimals: false,
      countSymbols: "+",
      counterText: "Hospitals",
      isVisible: true,
    },
    {
      id: 2,
      count: 1.3,
      decimals: true,
      countSymbols: "L+",
      counterText: "Bills",
      isVisible: true,
    },
    {
      id: 3,
      count: 1.0,
      decimals: false,
      countSymbols: "Trillion",
      counterText: "Papers saved",
      isVisible: true,
    },
  ],
  TestimonialSection: {
    screenTitle: "Testimonials",
    mainTitle: "Words from Client",
    testimonialsList: [
      {
        id: 1,
        image: Images.icon.icon_34,
        text: "Finsol has been vital to our company’s growth in ecommerce over the last 3 years. They are knowledgeable, efficient, communicative, and are always able to provide a strong resource for any needs that arise. Finsol is quite possibly the most beneficial outside resource we’ve ever had.",
        author: " ",
        designation: " ",
      },
      {
        id: 2,
        image: Images.icon.icon_34,
        text: "Our relationship with Finsol covered multiple areas such as software development of server-based systems with desktop and mobile clients, software testing, software consulting and system integration for our customer.",
        author: " ",
        designation: "",
      },
      {
        id: 3,
        image: Images.icon.icon_34,
        text: "Over 11 years, finsol has become an indispensable part of our team. We are pleased to work with Finsol and would recommend them as a valued and trustworthy business partner. We’ve already improved our ability to track our complex commission structure.",
        author: " ",
        designation: " ",
      },
      {
        id: 4,
        image: Images.icon.icon_34,
        text: "Fantastic Vendor. Trustworthy, highly skilled and experienced team members. We've relied on them exclusively since 2015 and look forward to continuing that relationship. As President of this Hospital, I sleep better knowing Finsol has got our backs 24X7.",
        author: "",
        designation: " ",
      },
    ],
  },
  PartnerSection: [
    { id: 1, image: Images.partner.Plogo_07 },
    { id: 2, image: Images.partner.Plogo_08 },
    { id: 3, image: Images.partner.Plogo_09 },
    { id: 4, image: Images.partner.Plogo_12 },
    { id: 5, image: Images.partner.Plogo_13 },
  ],
  contactSection: {
    sectionImage: Images.home.home_img_4,
    sectionpara:
      " We offer a variety of services that make it easy for you to get started with our intuitive platform and help you to transform and scale your business. If you have any questions,please feel free to get in touch with us at info@finsolconsultancy.com or call us at +91 891 2762072 to learn more about our offerings.",
  },
  FaqSection: {
    title: "QUESTIONS & ANSWERS",
    mainTitle: "Any Questions? Find here.",
    para: "Don’t find your answer here? just send us a message for any query.",
    link: {
      path: "/contact",
      text: "Contact us",
    },
    questions: [
      {
        id: 1,
        question: "How do I choose the right software development provider?",
        answer:
          "When outsourcing, communication is the most crucial aspect to address upfront; find out how they plan to contact you and go beyond any language and geographical barriers. Consider their professional experience. ",
      },
      {
        id: 2,
        question: "When should I use staff augmentation?",
        answer:
          "For clients who already have a capable team and the appropriate infrastructure in place to manage the core of project development, Finsol suggests an augmentation-based method. The bulk of the time, limited bandwidth, and particular resource availability hamper project flow. ",
      },
      {
        id: 3,
        question: "How much does it cost to build a customized software and what is your pricing strategy ?",
        answer:
          "Don’t worry. We’ll work closely with you to ballpark the work and see if it makes sense to keep talking. We usually work on fixed pricing policy. We have another mode of pricing policy that works where the scope of work isn’t precise. It helps everyone stick to the projected pricing model’s budget and timeline, no matter which model you choose.  ",
      },
      {
        id:4,
        question: "Insourcing vs. outsourcing, cost/benefit analysis",
        answer:
          "Companies all over the world are using outsourcing to fulfil their needs for software development, delegating more technical jobs like accounting, development, and IT-related services.Outsourcing has advantages for increased earning potential, possibilities, and general progress.",
      },
      {
        id: 5,
        question: "What are the different modes of service that Finsol offer?",
        answer:
          "Finsol provides software development services on-site  better stability in execution,reduction of overheads and quicker communication and off-shore the solution to build is well know,the problem is simple,the dependence on the product is limited and total cost of operation will be low.",
      },
      {
        id: 6,
        question:
          "I have an old software product that needs to be redone. Do you modernize legacy app?",
        answer:
          " Yes, of course! assuming upgrading your legacy app will have a positive impact on your business.",
      },
    ],
  },
  popup:{
    homeAdd:{
      src: Images.popup.adlogo,
      alt:"adLogo",
    },
    popupImg:{
      src:Images.popup.adimg,
      alt:"adImg"
    },
    DatePlace:{
      date:'16-20 OCT 2023',
      alt:"Dubai World Trade Center",
    },
    VenueAddress:{
      venue:'Hall 7',
      subVenue:"H7-E04",
    },
  },
  FooterSection: {
    FooterText:
      "  We can build exceptional solutions across a breadth of devices being it a desktop, tablet, or a mobile device.",
    Footerlinks: [
      {
        id: 1,
        text: "Links",
        subLinks: [
          {
            link: "Home",
            url: "/",
          },
          // {
          //   link: "Blog",
          //   url: "/blog",
          // },
          {
            link: "Careers",
            url: "/careers",
          },
          {
            link: "Contact",
            url: "/contact",
          },
        ],
        colSize: 2,
        multiCase: false,
      },
      {
        id: 2,
        text: "Services",
        subLinks: [
          {
            link: "Application Development",
            url: "/services/application-development",
          },
          {
            link: "Business As Usual",
            url: "/services/business-as-usual",
          },
          {
            link: "Project Management Office",
            url: "/services/project-management-office",
          },
          {
            link: "Production Support",
            url: "/services/production-support",
          },
          {
            link: "IT Services & Cloud Solution",
            url: "/services/it-services-and-cloud-solution",
          },

          {
            link: "B2B Projects execution",
            url: "/services/b2b-projects-execution",
          },
          {
            link: "Resource Augmentation",
            url: "/services/resource-augmentation",
          },
          {
            link: "Quality Assurance",
            url: "/services/quality-assurance",
          },

          { link: "UI/UX", url: "/services/ui-ux" },

          {
            link: "Migration Projects in Cards & Payments",
            url: "/services/migration-projects",
          },
        ],
        multiCase: false
        ,
        colSize: 4,
      },
      {
        id: 3,
        multiCase: true,
        Links: [
          {
            text: "Products",
            subLinks: [
              {
                link: "Health Care ERP",
                url: "/product/health-card-erp",
              },
              {
                link: "Sugar Industry ERP",
                url: "/product/erp",
              },
              {
                link: "CRM",
                url: "/product/crm",
              },
            ],
          },
          {
            text: "Industry",
            subLinks: [
              {
                link: "Banking & Finance",
                url: "/industry/banking-finance",
              },
              {
                link: "Healthcare",
                url: "/industry/health-care",
              },
            ],
          },
          {
            text: "Legal",
            subLinks: [
              {
                link: "Privacy Policy",
                url: "/policy/privacy-policy",
              },
              {
                link: "Cookies",
                url: "/policy/cookies",
              },
            ],
          },
        ],
        colSize: 2,
      },
    ],
    socialMedia: [
      { id: 1, icon: "fab fa-facebook-f", link: "#" },
      { id: 2, icon: "fab fa-twitter", link: "#" },
      { id: 3, icon: "fab fa-linkedin-in", link: "#" },
    ],
    CopyrightBar: {
      LegalLinks: [
        { id: 1, text: "Privacy & Terms", Link: "/policy/privacy-policy" },
        { id: 2, text: "Cancellation And Refund Policies", Link: "/policy/refund-policy" },
      ],
    },
  },
};

export default Home;
