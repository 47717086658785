import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

function SectionTwo(props) {
  const data = props.data;
  return (
    <div className="service-details position-relative section-space">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="service-details-meta ms-xxl-4">
              <ScrollAnimation animateIn="animate__fadeIn">
                <h2 className="main-title">{data.sectionTitle}</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn">
                <p>{data.sectionPara}</p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn">
                <img src={data.sectionImage} alt="" className="w-50 m-auto" />
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn">
                {data.subSection.title && (
                  <h3 className="sub-title">{data.subSection.title}</h3>
                )}
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn">
                {data.subSection.text && <p>{data.subSection.text}</p>}
              </ScrollAnimation>

              <div className="row">
                {data.cards &&
                  data.cards.map((card) => (
                    <div key={card.id} className="col-md-4">
                      <ScrollAnimation animateIn="animate__fadeIn">
                        <div className="d-flex flex-column block-style-ten color-two ps-2 pe-2 text-center tran3s mt-10">
                          <div className="icon d-flex align-items-end">
                            <img src={card.icon} alt="" className="m-auto" />
                          </div>
                          <h6 className="mt-4 text-capitalize">{card.text}</h6>
                          {card.para && (
                            <p className="fs-16px lh-base">{card.para}</p>
                          )}
                        </div>
                      </ScrollAnimation>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionTwo;
