import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./components/pages/about";
import CareerPage from "./components/pages/career";
import Contact from "./components/pages/contact/contact";
import Home from "./components/pages/home/home";
import IndustryPage from "./components/pages/industry";
import Policy from "./components/pages/policy";
import ProductPage from "./components/pages/product";
import ServicePage from "./components/pages/services";
import Layout from "./components/utility/layout/layout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index exactly element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services/:service" element={<ServicePage />} />
          <Route path="/product/:product" element={<ProductPage />} />
          <Route path="/industry/:industry" element={<IndustryPage />} />
          {/* <Route path="/blog" element={<BlogPage />} /> */}
          {/* <Route path="/blog/:blogpost" element={<BlogPost />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/careers" element={<CareerPage />} />
          <Route path="/policy/:policies" element={<Policy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
