import Images from "../images";

const carrersData = {
  currentPage: "Careers",
  innerBanner: {
    type: "center",
    text1: "Careers",
    text2: "Opportunities",
    image: Images.assets.applicationDevelopment,
    imageText: "",
  },
  section1: {
    screenTitle: "Careers Opportunities",
    sectionTitle: "Work Environment",
    sectionPara:
      "We believe that a satisfied and happy employee brings lot of Enthusiasm, Creativity and Commitment to the work environment. Believing in the above principle, all our employee policies are designed to ensure every employee is honored and ensures employees remain satisfied. This will help our employees to achieve new accomplishments in their career. We also ensure the idle period are properly utilized to garnish the employee skills with continuous training program.",
    image: Images.carrer.carrer_img,
  },
  jobCardSection: {
    title: "",
    subTitle: "",
    tabList: [
      {
        id: 1,
        icon: Images.darkLogo,
        jobTitle: "UX Designer",
        experience: "5+ years of experience ",
        location: "Visakhapatnam, Andhra Pradesh, India",
        postedDate: "Posted on 23-07-2023",
        shift: "Full-Time",
        qualification:
          "B.Tech / B.E. in Computer Science / ECE , B.Sc(Computers)",
        summary: {
          title: "Job Summary",
          description: [
            {
              title:"",
              list:[
                "We are seeking a talented and creative UX Designer to join our team and play a pivotal role in designing user-centric digital experiences. As a UX Designer, the resource will be responsible for understanding user needs, crafting intuitive and visually appealing interfaces, and optimizing the overall user experience across our products and platforms. The candidate's work will involve collaborating with cross-functional teams, conducting user research, wireframing, prototyping, and delivering polished designs that align with our brand identity and business objectives."
              ]
            },
            {
              title: "Responsibilities",
              list: [
                "User Research: Conduct user interviews, surveys, and usability tests to gain insights into user behaviours, pain points, and preferences. Utilize these insights to inform the design process and make data-driven decisions.",
                "Wireframing and Prototyping: Create low and high-fidelity wireframes and interactive prototypes to visualize and validate design concepts. Iterate on designs based on feedback and testing results.",
                "User Interface Design: Develop visually appealing, consistent, and user-friendly interfaces that adhere to UX best practices and brand guidelines. Ensure designs are accessible and responsive across different devices.",
                "Collaborative Design: Work closely with product managers, developers, and other stakeholders to understand project requirements and constraints. Collaborate effectively to reach consensus and align on design solutions.",
                "Information Architecture: Organize and structure information in a logical and intuitive manner, ensuring ease of navigation and a seamless user journey.",
                "Usability Testing: Plan and conduct usability testing sessions to evaluate the effectiveness of design solutions and identify areas for improvement.",
                "Design Systems: Contribute to the development and maintenance of design systems, style guides, and pattern libraries to promote consistency and scalability across products.",
                "Stay Current with UX Trends: Stay up-to-date with industry trends, emerging technologies, and design best practices. Share knowledge with the team and apply innovative ideas to enhance the user experience."
              ],
            },
            {
              title:"Requirements",
              list:[
                "Proven 5+ years of experience as a UX Designer, ideally in designing digital products and web/mobile applications.",
                "Strong portfolio showcasing a diverse range of UX/UI projects, highlighting the design process and the impact on user experience.",
                "Proficiency in industry-standard design tools such as Sketch, Adobe XD, Figma, or similar.",
                "Familiarity with user research techniques and usability testing methodologies.",
                "Solid understanding of user-centered design principles, information architecture, and interaction design.",
                "Knowledge of front-end development technologies (HTML, CSS, JavaScript) to effectively communicate with developers.",
                "Excellent communication and presentation skills to articulate design decisions and advocate for the user's perspective.",
                "Ability to work in a fast-paced environment, manage multiple projects, and meet tight deadlines.",
                "Bachelor's degree in Design, Human-Computer Interaction (HCI), or a related field (or equivalent experience)."
              ]
            }
          ],
        },
      },
      {
        id: 2,
        icon: Images.darkLogo,
        jobTitle: "AI/ML Developers",
        experience: "5+ years of experience ",
        location: "Vizag ,Hyderabad",
        postedDate: "Posted on 23-07-2023",
        shift: "Full-Time",
        qualification:
          "Bachelor's or Master's degree in Computer Science, Data Science, Artificial Intelligence, Machine Learning, or a related field.",
        summary: {
          title: "Job Summary",
          description: [
            {
              title:"",
              list:[
                "We are seeking an experienced and talented AI/ML Senior Resource to join our dynamic and innovative team. As a Senior AI/ML professional, the resource will be responsible for driving the development and implementation of cutting-edge Artificial Intelligence and Machine Learning solutions across our organization. The candidate's expertise and leadership will be critical in solving complex business challenges and optimizing various processes using advanced AI and ML techniques."
              ]
            },
            {
              title: "Responsibilities",
              list: [
                "AI/ML Solution Development: Lead the design, development, and deployment of AI/ML models and algorithms to address business problems and opportunities. Collaborate with cross-functional teams to understand their requirements and translate them into actionable AI/ML solutions.",
                "Data Analysis: Conduct in-depth data analysis to identify patterns, trends, and insights. Utilize data pre-processing techniques to ensure data quality and relevance for model training.",
                "Model Selection and Training: Identify appropriate AI/ML models based on the problem at hand and conduct training and optimization processes. Fine-tune models to achieve optimal performance and accuracy.",
                "Performance Evaluation: Evaluate the performance of AI/ML models using relevant metrics and implement necessary adjustments to enhance their effectiveness.",
                "Deployment and Integration: Work closely with the engineering team to integrate AI/ML solutions into existing systems or products, ensuring scalability, robustness, and maintainability.",
                "Research and Innovation: Stay updated with the latest advancements in AI/ML technologies and industry trends. Continuously explore new approaches and techniques to improve the organization's AI/ML capabilities.",
                "Mentoring and Leadership: Provide technical leadership and guidance to junior members of the AI/ML team. Help in their skill development and contribute to fostering a collaborative learning environment.",
                "Data Privacy and Security: Ensure compliance with data privacy regulations and maintain high standards of data security throughout the AI/ML development process.",
                "Documentation: Create detailed technical documentation for AI/ML models, algorithms, and processes. Prepare clear and concise reports for management and stakeholders."
              ],
            },
            {
              title:"Requirements",
              list:[
                 "Educational Background: Bachelor's or Master's degree in Computer Science, Data Science, Artificial Intelligence, Machine Learning, or a related field.",
                 "Experience: A minimum of 5 years of hands-on experience in designing, developing, and deploying AI/ML solutions. Proven track record of successfully implementing AI/ML projects.",
                 "Programming Skills: Proficiency in programming languages such as Python, R, or Java for AI/ML development.",
                 "AI/ML Libraries: Strong knowledge of popular AI/ML libraries and frameworks like TensorFlow, Keras, PyTorch, scikit-learn, etc.",
                 "Data Manipulation: Solid understanding of data manipulation, feature engineering, and data visualization techniques.",
                 "Deep Learning: Experience in building and training deep learning models for various applications such as image recognition, natural language processing, etc.",
                 "Problem-solving: Strong analytical and problem-solving skills, with the ability to think creatively and critically.",
                 "Communication: Excellent verbal and written communication skills. Ability to present complex technical concepts to non-technical stakeholders.",
                 "Team Player: A collaborative and team-oriented mindset, with the ability to work effectively in a fast-paced environment.",
              ]
            },
            {
              title:"Preferred Qualifications",
              list:[
                "Experience with big data technologies and distributed computing.",
                "Previous experience in leading AI/ML projects or teams.",
                "Knowledge of cloud platforms such as AWS, Azure, or GCP for AI/ML deployments.",
                "Familiarity with DevOps practices for AI/ML model deployment and monitoring.",
                "Join us in our mission to leverage AI/ML to revolutionize our organization's capabilities and drive transformative business outcomes. If you are passionate about pushing the boundaries of AI/ML and are eager to make a significant impact, we would love to hear from you. Apply now and become a vital part of our forward-thinking team!"
              ]
            }
          ],
        },
      },
      {
        id: 3,
        icon: Images.darkLogo,
        jobTitle: "Sales Associates",
        experience: "Experience in software sales",
        location: "Bhubaneswar, Andhra Pradesh ,Kurnool, Kadapa, Prakasam & Nellore Districts.",
        postedDate: "Posted on 23-07-2023",
        shift: "Full-Time",
        qualification:
          "Bachelor's degree in Business, Marketing, Computer Science, or a related field.",
        summary: {
          title: "Job Summary",
          description: [
            {
              title:"",
              list:[
                "We are seeking a highly motivated and dynamic Software Sales Associate to join our sales team. As a Software Sales Associate, you will be responsible for identifying potential clients, developing new business opportunities, and driving sales of our cutting-edge software products and solutions. Your primary focus will be on understanding customer needs, presenting the value of our software offerings, and closing deals to achieve revenue targets."
              ]
            },
            {
              title: "Key Responsibilities",
              list: [
                 "Prospect and Identify Leads: Conduct market research to identify potential clients and generate leads through various channels, such as cold calling, networking events, social media, and referrals.",
                 "Customer Needs Analysis: Engage with prospective clients to understand their business challenges and requirements. Conduct needs analysis to align our software solutions with the client's needs effectively.",
                 "Sales Presentations: Prepare and deliver compelling sales presentations and demonstrations of our software products to showcase their features, benefits, and value proposition.",
                 "Relationship Building: Cultivate and maintain strong relationships with prospects and clients to enhance customer satisfaction and loyalty. Act as a trusted advisor to clients throughout the sales process.",
                 "Negotiation and Closing: Lead negotiation efforts to arrive at mutually beneficial agreements with clients. Work towards closing sales deals to achieve monthly, quarterly, and annual revenue targets.",
                 "Sales Pipeline Management: Maintain accurate and up-to-date records of sales activities, opportunities, and customer interactions in the CRM system. Track the sales pipeline and provide regular progress reports to the sales management team.",
                 "Product Knowledge: Stay up-to-date with the latest developments in our software products and solutions. Continuously improve your understanding of industry trends and competitor offerings.",
                 "Collaborate with Sales Team: Coordinate with the sales team to strategize and optimize sales efforts. Collaborate with other departments, such as marketing and customer support, to ensure a seamless customer experience.",
                 "Sales Metrics: Analyze sales data and metrics to identify trends and areas for improvement. Propose and implement strategies to maximize sales performance.",
              ],
            },
            {
              title:"Qualifications and Requirements",
              list:[
                "Bachelor's degree in Business, Marketing, Computer Science, or a related field.",
                "Proven experience in software sales or a similar role, preferably in the technology industry.",
               "Strong communication and interpersonal skills, with the ability to build rapport and foster long-term customer relationships.",
               "Excellent presentation and negotiation abilities.",
               "Goal-oriented with a track record of meeting or exceeding sales targets.",
               "Familiarity with CRM software and sales tools is a plus.",
               "Self-motivated, driven, and able to work independently as well as part of a team.",
               "Willingness to travel for client meetings and industry events, as needed.",
               "Knowledge of software development and technology trends is advantageous.",
              ]
            }
          ],
        },
      },
      {
        id: 4,
        icon: Images.darkLogo,
        jobTitle: "Job for Freshers",
        experience: "Freshers",
        location: "Visakhapatnam, Andhra Pradesh, India",
        postedDate: "Posted on 24-08-2022",
        shift: "Full-Time",
        qualification:
          "B.Tech / B.E. in Computer Science / ECE , B.Sc(Computers)",
        summary: {
          title: "Job Summary",
          description: [
           
            {
              title: "Description",
              list: [
                "B.Tech / B.E. in Computer Science / ECE ",
                "Should have good Analytical and critical thinking skills",
                "Needs to have Good communication skills",
                "Should be able to maintain composure under pressure",
                "Should be able to work in a team",
                "Good observation skills and a willingness to learn are required",
                "Experience / Internships in a technical field would be advantageous",
              ],
            },
          ],
        },
      },
      {
        id: 5,
        icon: Images.darkLogo,
        jobTitle: "Java / Angular Developer",
        experience: "2 to 6 years",
        location: "Visakhapatnam, Andhra Pradesh, India",
        postedDate: "Posted on 24-08-2022",
        shift: "Full-Time",
        qualification: "Bachelor's (Preferred)",
        summary: {
          title: "Job Summary",
          description: [
            {
              title: "Description",
              list: [
                "Design, implement, and maintain web application phases",
                "Conduct software analysis, programming, testing, and debugging",
                "Identifying production and non-production application issues",
                "Transforming requirements into stipulations",
                "Develop, test, implement and maintain application software",
                "Ability to develop logical and high-quality code which meets functional specifications along with technical requirements for reusability, maintainability, and scalability when appropriate application code for java programs",
                "Ability to perform technical analysis based on requirements to establish architecture approach and create detailed design in support of the requirements",
                "Excellent written and verbal communication skills, worked for foreign client would be an added advantage",
              ],
            },
            {
              title: "Key Skills and Experience",
              list: [
                "Min 4 to 6 years of experience in the JAVA/J2EE",
                "Experience with RESTFUL Web services",
                "Experience in portal & web content management",
                "Hands-on experience on Angular, Javascript frameworks like Prime NG and JQuery",
                "Hands-on experience in software development using J2EE Frameworks – Spring, Spring Boot, Micro services, Hibernate",
                "Good to have experience or Knowledge in ReactJS, different AWS services like S3, EC2, DynamoDB, Lambda function",
                "Exposure in any of the Databases -MySQL/Postgres/Oracle/DB2 etc",
              ],
            },
          ],
          skills: {
            title: "Technical Skills",
            list: [
              "OOPS, HTML, Javascript, Angular, Ajax, JSON, Bootstrap, Prime NG, Java, J2EE, Spring MVC, Spring Boot , Spring security and Spring Batch SOAP, REST API, Logging Framework, Hibernate, XML, JEE (Servlets/JSP/JMS), web services experience, microservices, Multithreading, GIT",
            ],
          },
        },
      },
    ],
  },
};
export default carrersData;
