import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Home from "../../../../common/data/home";

function FeatureSectionSeventeen() {
  const data = Home.ProductSection;
  return (
    <div className="fancy-feature-seventeen position-relative section-space">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-5">
            <div className="title-style-three text-center text-lg-start">
              <ScrollAnimation animateIn="animate__fadeIn">
                <h2 className="main-title">{data.title}</h2>
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-xl-6 col-lg-7">
            <ScrollAnimation animateIn="animate__fadeIn">
              <p className="m0 text-center text-lg-start md-pt-30">
                {data.subText}
              </p>
            </ScrollAnimation>
          </div>
        </div>
        <div className="row justify-content-center pt-20 ">
          {data.productcard.map((card, index) => (
            <div key={card.id} className="col-lg-4 col-md-6 col-12">
              {/* <ScrollAnimation
                animateIn="animate__bounceInUp"
                delay={(index + 1) * 5}
              > */}
                <div className="block-style-twelve p-lg-4 ps-lg-0">
                  <div className="icon d-flex align-items-end">
                    <img src={card.icon} alt="" />
                  </div>
                  <h5 className="my-3">
                    {card.heading}
                    {/* <Link to={card.link}>{card.heading}</Link> */}
                  </h5>
                  <p>{card.body}</p>
                  {/* <Link to={card.link} className="tran3s more-btn">
                    <img src={Images.icon.icon_20} alt="" />
                  </Link> */}
                </div>
              {/* </ScrollAnimation> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeatureSectionSeventeen;
