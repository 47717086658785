import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import Home from "../../../../common/data/home";
function FeatureSectionEighteen() {
  const data = Home.featureSectionEighteen;
  return (
    <div className="fancy-feature-eighteen position-relative section-space">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-5 order-1 order-md-0 mt-40">
            <ScrollAnimation animateIn="animate__fadeIn">
              <img src={data} alt="" className="transform-img-meta margin " />
            </ScrollAnimation>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-7 order-0 order-md-1">
            <div className="block-style-two">
              <ScrollAnimation animateIn="animate__fadeIn">
                <div className="title-style-three">
                  <div className="sc-title">Over 80+ Client</div>
                  <h2 className="main-title">
                    Quality <span>Industrial</span> Services
                  </h2>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn">
                <p className="pt-20 pb-25 lg-pb-20 lh-base text-justify">
                  We are experts in a range of domains and provide
                  top-of-the-line products and services in several verticals
                  such as Banking, Finance, Healthcare and Manufacturing.
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn">
                <ul className="style-none list-item color-rev">
                  <li>Seamless Banking and Finance tools for your business.</li>
                  <li>Healthcare Management Solutions.</li>
                  <li>Testing Center Of Excellence (TCoE) offerings.</li>
                </ul>
              </ScrollAnimation>
              <Link to="/about" className="btn-eight mt-50 lg-mt-30">
                More about us
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="shapes oval-one"></div>
      <div className="shapes oval-two"></div>
      <div className="shapes oval-three"></div>
    </div>
  );
}

export default FeatureSectionEighteen;
