const PolicyData = {
  policyPage: [
    {
      id: "privacy-policy",
      currentPage: "Privacy Policy",
      innerBanner: {
        type: "center",
        text1: "Privacy",
        text2: "Policy",
        image: "",
        imageText: "",
      },
      sections: [
        {
          heading3: "Privacy Statement",
          paragraph: [
            "Finsol Limited, its subsidiaries and branch operations hereinafter referred as ‘Finsol’, ‘we’, ‘us’ or ‘our’ is committed to respect your privacy and choices. The privacy statement highlights our privacy practices regarding Personal Information that we collect and process depending on your association with Finsol and nature of processing activity in compliance to applicable data privacy regulations.",
            "This privacy statement highlights our privacy practices regarding Personal Information that we collect and process about you through this website, www.Finsol.com (the “Site”) and also for those Personal Information that you provide us while participating in our events and campaigns.",
          ],
        },
        {
          heading4:
            "Personal Information we collect and process and how we use it",
        },
        {
          heading4: "I.1 Personal Information that we process",
          paragraph: [
            "Finsol Limited, its subsidiaries and branch operations hereinafter referred as ‘Finsol’, ‘we’, ‘us’ or ‘our’ is committed to respect your privacy and choices. The privacy statement highlights our privacy practices regarding Personal Information that we collect and process depending on your association with Finsol and nature of processing activity in compliance to applicable data privacy regulations.",
            "This privacy statement highlights our privacy practices regarding Personal Information that we collect and process about you through this website, www.Finsol.com (the “Site”) and also for those Personal Information that you provide us while participating in our events and campaigns.",
            "We collect the following categories of Personal Information:",
          ],
          bulletpoints: [
            "Information that you provide via our website, including information you provide when you register on our website e.g. name, email address, designation, company, country, name of your university, your field of academic study/concentration, expected year and month of your graduation, and telephone number",
            "Information about your computer and about your visits to and use of this Site, such as your Internet Protocol (IP) address, demographics, your computers’ operating system, and browser type and information collected via cookies",
            "Cookie related details are provided in the Cookie Policy",
          ],
        },
      ],
    },
    {
      id: "cookies",
      currentPage: "Cookies",
      innerBanner: {
        type: "center",
        text1: "Cookies",
        text2: "Policy",
        image: "",
        imageText: "",
      },
      sections: [
        {
          heading3: "PRIVACY STATEMENT",
          paragraph: [
            "Finsol Limited, its subsidiaries and branch operations hereinafter referred as ‘Finsol’, ‘we’, ‘us’ or ‘our’ is committed to respect your privacy and choices. The privacy statement highlights our privacy practices regarding Personal Information that we collect and process depending on your association with Finsol and nature of processing activity in compliance to applicable data privacy regulations.",
            "This privacy statement highlights our privacy practices regarding Personal Information that we collect and process about you through this website, www.Finsol.com (the “Site”) and also for those Personal Information that you provide us while participating in our events and campaigns.",
          ],
        },
        {
          heading4:
            "Personal Information we collect and process and how we use it",
        },
        {
          heading4: "I.1 Personal Information that we process",
          paragraph: [
            "Finsol Limited, its subsidiaries and branch operations hereinafter referred as ‘Finsol’, ‘we’, ‘us’ or ‘our’ is committed to respect your privacy and choices. The privacy statement highlights our privacy practices regarding Personal Information that we collect and process depending on your association with Finsol and nature of processing activity in compliance to applicable data privacy regulations.",
            "This privacy statement highlights our privacy practices regarding Personal Information that we collect and process about you through this website, www.Finsol.com (the “Site”) and also for those Personal Information that you provide us while participating in our events and campaigns.",
            "We collect the following categories of Personal Information",
          ],
          bulletpoints: [
            "Information that you provide via our website, including information you provide when you register on our website e.g. name, email address, designation, company, country, name of your university, your field of academic study/concentration, expected year and month of your graduation, and telephone number",
            "Information about your computer and about your visits to and use of this Site, such as your Internet Protocol (IP) address, demographics, your computers’ operating system, and browser type and information collected via cookies",
            "Cookie related details are provided in the Cookie Policy",
          ],
        },
      ],
    },
    {
      id: "refund-policy",
      currentPage: "Refund Policy",
      innerBanner: {
        type: "center",
        text1: "Cancellation And",
        text2: "Refund Policies",
        image: "",
        imageText: "",
      },
      sections: [
        {
          heading3: "Cancellation And Refund Policies",
          paragraph: [
            "Due to limited seating, we request that you cancel at least 48 hours before a scheduled class. This gives us the opportunity to fill the class. You may cancel by phone or online here. If you have to cancel your class, we offer you a credit to your account if you cancel before the 48 hours, but do not offer refunds. You may use these credits towards any future class. However, if you do not cancel prior to the 48 hours, you will lose the payment for the class. The owner has the only right to be flexible here.",
            "Cancellations made 7 days or more in advance of the event date, will receive a 100% refund. Cancellations made within 3 - 6 days will incur a 20% fee. Cancellations made within 48 hours to the event will incur a 30% fee.",
          ],
        },
        {
          heading4:
            "Personal Information we collect and process and how we use it",
        },
        {
          heading4: "I.1 Personal Information that we process",
          paragraph: [
            "I understand that I am holding a spot so reservations for this event are nonrefundable. If I am unable to attend I understand that I can transfer to a friend.",
            "If your cancellation is at least 24 hours in advance of the class, you will receive a full refund. If your cancellation is less than 24 hours in advance, you will receive a gift certificate to attend a future class. We will do our best to accommodate your needs.",
            "You may cancel your class up to 24 hours before the class begins and request to receive a full refund. If cancellation is made day of you will receive a credit to reschedule at a later date. Credit must be used within 90 days.",
          ],
          bulletpoints: [
            "Information that you provide via our website, including information you provide when you register on our website e.g. name, email address, designation, company, country, name of your university, your field of academic study/concentration, expected year and month of your graduation, and telephone number",
            "Information about your computer and about your visits to and use of this Site, such as your Internet Protocol (IP) address, demographics, your computers’ operating system, and browser type and information collected via cookies",
            "Cookie related details are provided in the Cookie Policy",
          ],
        },
      ],
    },
  ],
};

export default PolicyData;
