import React from "react";
import {
  Accordion,
  AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel
} from "react-accessible-accordion";
import { Link } from "react-router-dom";
import Home from "../../../../common/data/home";
// import 'react-accessible-accordion/dist/fancy-example.css';

function FAQSection() {
  // const [arrowIcon , setArrowIcon] = false;

  function arrow() {}

  return (
    <div className="fancy-feature-twenty position-relative section-space">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="block-style-five pe-xxl-5 me-xxl-5 md-pb-50">
              <div className="title-style-three">
                <div className="sc-title">{Home.FaqSection.title}</div>
                <h2 className="main-title">{Home.FaqSection.mainTitle}</h2>
              </div>
              <p className="pt-20 pb-15">{Home.FaqSection.para}</p>
              <Link
                to={Home.FaqSection.link.path}
                className="btn-eight ripple-btn"
              >
                {Home.FaqSection.link.text}
              </Link>
            </div>
          </div>

          <div className="col-lg-7 faqsection  faq-buttons ">
            <Accordion onChange={arrow()}>
              {Home.FaqSection.questions.map((faq) => (
                <AccordionItem key={faq.id}>
                  <AccordionItemHeading>
                    <AccordionItemButton>{faq.question}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="fqa">{faq.answer}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
        <img
          src="images/shape/shape_36.svg"
          alt=""
          className="shapes shape-one"
        />
        <div className="shapes oval-one"></div>
      </div>
    </div>
  );
}

export default FAQSection;
