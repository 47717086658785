import Images from "../images";

const contactData = {
  currentPage: "Contact",
  innerBanner: {
    type: "center",
    text1: "Get in",
    text2: "Touch",
    image: Images.assets.applicationDevelopment,
    imageText: "",
  },

  address: [
    {
      id: 1,
      icon: Images.contact.location,
      iconText: "",
      title: "Registered Office Address",
      text: "Reg.Office:no.7(O), no.11(N), Shanthi Nagar, 4th Street, Adambakkam, Chennai-600 088, India.",
    },
    {
      id: 2,
      icon: Images.contact.email,
      iconText: "",
      title: "Branch Office  At Visakhapatnam",
      text: "SDF-1/25 D, Phase-I, VSEZ, Duvvada, Visakhapatnam-530 046, India.",
    },
    {
      id: 3,
      icon: Images.contact.call,
      iconText: "",
      title: "Branch Office  At  Hyderabad",
      text: "Door No.7-1-618/ATC/104-A, 1st Floor, Aditya Trade Centre, Ameerpet, Hyderabad, Telangana, 500038.",
    },
  ],
};
export default contactData;
