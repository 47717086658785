import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import Home from "../../../../common/data/home";
function BannerSection() {
  const data = Home.BannerSection;
  return (
    <div className="hero-banner-five">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxl-6 col-lg-6 col-12  text-center text-lg-start ">
            <ScrollAnimation initiallyVisible animateIn="animate__fadeIn">
              <h1 className="hero-heading ps-3">
                {data.bannerHeading}
                <span>{data.bannerHeadingHightlight}</span>
              </h1>
            </ScrollAnimation>
            <ScrollAnimation initiallyVisible animateIn="animate__fadeIn">
              <p className="text-md mb-40 pt-20 pe-xl-4 md-pt-20 md-mb-30 lh-base ps-3">
                {data.bannerPara}
              </p>
            </ScrollAnimation>
            <ScrollAnimation initiallyVisible animateIn="animate__fadeIn">
              <ul className="style-none button-group d-flex align-items-center justify-content-lg-start justify-content-center ps-3">
                <li className="me-4">
                  <Link to="/contact" className="demo-btn ripple-btn tran3s">
                    {data.bannerbtntext}
                  </Link>
                </li>
                {/* <li>
                    <a
                      className="fancybox video-icon tran3s"
                      data-fancybox=""
                      href="https://www.youtube.com/embed/aXFSJTjVjw0"
                    >
                   
                      <i className="fas fa-play" style={{background: '#99b3b1'}}></i>
                    </a>
                  </li> */}
              </ul>
            </ScrollAnimation>
          </div>
          {/* <div className="col-xxl-6 col-lg-6 col-12 position-relative">
              <ScrollAnimation animateIn="animate__fadeIn" initiallyVisible>
                <img src={data. banner_img_1} alt="" className="" />
              </ScrollAnimation>
            </div> */}
        </div>
      </div>
    </div>
  );
}

export default BannerSection;
