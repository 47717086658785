import React, { useLayoutEffect } from "react";
import BannerSection from "./bannerSection";
import ContactSection from "./contactSection";
import FAQSection from "./faqSection";
import FeatureSectionEighteen from "./featureSectionEighteen";
import FeatureSectionNineteen from "./featureSectionNineteen";
import FeatureSectionSeventeen from "./featureSectionSeventeen";
import TestimonailSection from "./testimonialSection";
import TCOESection from "./tcoeSection";

function Home() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="main-page-wrapper">
        <BannerSection />
        <FeatureSectionSeventeen />
        <FeatureSectionEighteen />
        <FeatureSectionNineteen />
        {/* <TCOESection /> */}
        {/* <CounterSection /> */}
        <TestimonailSection />
        <FAQSection />
        <ContactSection />
        {/* <PartnerSection /> */}

        {/* <div className="blog-section-three position-relative pt-70 lg-pt-40">
          <div className="container">
            <div className="title-style-three text-center mb-50 lg-mb-20">
              <div className="sc-title">RECENT NEWS</div>
              <h2 className="main-title">
                Inside Story & <span>Blog</span>
              </h2>
            </div>

            <div className="row gx-xxl-5">
              <div className="col-lg-4 col-sm-6 d-flex">
                <article className="blog-meta-one color-two tran3s mt-30">
                  <figure className="post-img m0">
                    <a href="blog-details.html" className="w-100 d-block">
                      <img
                        src="images/blog/blog_img_01.jpg"
                        alt=""
                        className="w-100 tran4s"
                      />
                    </a>
                  </figure>
                  <div className="post-data">
                    <div className="post-tag">
                      <a href="blog-details.html">Data Science</a> - 15 minute
                      read
                    </div>
                    <a href="blog-details.html" className="blog-title">
                      <h5>Make more daily productive working flow like pro.</h5>
                    </a>
                  </div>
                </article>
              </div>
              <div className="col-lg-4 col-sm-6 d-flex">
                <article className="blog-meta-one color-two tran3s mt-30">
                  <figure className="post-img m0">
                    <a href="blog-details.html" className="w-100 d-block">
                      <img
                        src="images/blog/blog_img_02.jpg"
                        alt=""
                        className="w-100 tran4s"
                      />
                    </a>
                  </figure>
                  <div className="post-data">
                    <div className="post-tag">
                      <a href="blog-details.html">UI/UX Design</a> - 10 minute
                      read
                    </div>
                    <a href="blog-details.html" className="blog-title">
                      <h5>
                        Internet of the things and why it’s becoming important
                        in the century.
                      </h5>
                    </a>
                  </div>
                </article>
              </div>
              <div className="col-lg-4 col-sm-6 d-flex">
                <article className="blog-meta-one color-two tran3s mt-30">
                  <figure className="post-img m0">
                    <a href="blog-details.html" className="w-100 d-block">
                      <img
                        src="images/blog/blog_img_03.jpg"
                        alt=""
                        className="w-100 tran4s"
                      />
                    </a>
                  </figure>
                  <div className="post-data">
                    <div className="post-tag">
                      <a href="blog-details.html">Marketing</a> - 8 minute read
                    </div>
                    <a href="blog-details.html" className="blog-title">
                      <h5>The power product and the quotient rules.</h5>
                    </a>
                  </div>
                </article>
              </div>
              <div className="col-lg-4 col-sm-6 d-lg-none d-flex">
                <article className="blog-meta-one color-two tran3s mt-30">
                  <figure className="post-img m0">
                    <a href="blog-details.html" className="w-100 d-block">
                      <img
                        src="images/blog/blog_img_01.jpg"
                        alt=""
                        className="w-100 tran4s"
                      />
                    </a>
                  </figure>
                  <div className="post-data">
                    <div className="post-tag">
                      <a href="blog-details.html">Data Science</a> - 15 minute
                      read
                    </div>
                    <a href="blog-details.html" className="blog-title">
                      <h5>Make more daily productive working flow like pro.</h5>
                    </a>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div> */}

        {/* <!-- Optional JavaScript _____________________________  -->

    	<!-- jQuery first, then Bootstrap JS -->
    	<!-- jQuery -->
		<script src="vendor/jquery.min.js"></script>
		<!-- Bootstrap JS -->
		<script src="vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
		<!-- AOS js -->
		<script src="vendor/aos-next/dist/aos.js"></script>
		<!-- Slick Slider -->
		<script src="vendor/slick/slick.min.js"></script>
		<!-- js Counter -->
		<script src="vendor/jquery.counterup.min.js"></script>
		<script src="vendor/jquery.waypoints.min.js"></script>
		<!-- Fancybox -->
		<script src="vendor/fancybox/dist/jquery.fancybox.min.js"></script>

		<!-- Theme js -->
		<script src="js/theme.js"></script> */}
      </div>
    </>
  );
}

export default Home;
