import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
function SectionFour(props) {
  const data = props.data;

  return (
    <div className="fancy-feature-nineteen position-relative section-space">
      <div className="container">
        <div className="block-style-thirteen">
          <ScrollAnimation animateIn="animate__fadeIn">
            {(data.sectionTitle || data.sectionpara) && (
              <div className="title-style-three pb-15">
                <div className="sc-title"></div>
                {data.sectionTitle && (
                  <h2 className="main-title">
                    {data.sectionTitle} <span></span>{" "}
                  </h2>
                )}
                {data.sectionpara && <p>{data.sectionpara}</p>}
              </div>
            )}
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn">
            <ul className="list-item row list-unstyled">
              {data.sectionListItems &&
                data.sectionListItems.map((items, index) => (
                  <li key={index + 1} className="col-12 col-sm-6">
                    <p className="numb tran3s">{index + 1}</p>
                    {items.heading && <h6>{items.heading}</h6>}
                    {items.text && <span>{items.text}</span>}
                  </li>
                ))}
            </ul>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
}
export default SectionFour;
