import images from "../../data/images";

const services = {
  service: [
    {
      id: "application-development",
      currentPage: "Application Development",
      innerBanner: {
        text1: "Application",
        text2: "Development",
        image: images.application_development.applicationdevelopment1,
        imageText: "",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Application Development",
        sectionPara: "",
        sectionListItems: [
          {
            id: 1,
            text: "Finsol has the people and the processes to help you take on any application development.",
          },
          {
            id: 2,
            text: "Finsol ensures that everything is timely, budgets are adhered to, and the software continues to be a feasible investment.",
          },
          {
            id: 3,
            text: "At Finsol, we provide application development solutions to some of the most challenging IT problems.",
          },
          {
            id: 4,
            text: "The three most common types of application development methodologies in Finsol.",
          },
        ],
        sectionImage: images.application_development.applicationdevelopment2,
        sectionImageText:
          "We have experience with a wide variety of applications, from basic tools like CRMs to complex custom software solutions with specialized in banking, cards and healthcare that require deep knowledge of programming languages. We have executed many applications development projects that works as web based software, mobile app, data base application, enterprise business application and Custom made application. Our team consists of highly skilled and experienced professionals who are dedicated to bringing your ideas to life.",
      },
      section2: {
        sectionTitle: "Waterfall Development",
        sectionPara:
          "The Finsol waterfall Model illustrates the software development process in a linear sequential flow. This means that any phase in the development process begins only if the previous phase is complete. In this waterfall model, the phases do not overlap. Every software developed by Finsol is different and requires a suitable SDLC approach to be followed based on the internal and external factors. Requirements are very well documented, clear, and fixed,technology is understood and is not dynamic,there are no ambiguous requirements",
        sectionImage: images.application_development.applicationdevelopment3,
        subSection: {
          title: "Agile Development",
          text: "Finsol’s Agile is an approach to software development that seeks the continuous delivery of working software created in rapid iterations. It is a way of thinking about collaboration and workflows, and it is a set of values which guide Finsol’s choices regarding what we make and how we make it. Finsol’s agile software development consists of small, self-organizing teams of software developers and business representatives regularly meeting in-person throughout the software development life cycle. Finsol’s agile software development consists of small, self-organizing teams of software developers and business representatives regularly meeting in-person throughout the software development life cycle. Lower costs, quality improvement and customer satisfaction",
        },
      },
      section3: {
        /* screenTitle: "WHY CHOOSE US",*/
        sectionTitle: "Rapid Application Development(RAD)",
        sectionPara:
          "Rapid Application Development or RAD means an adaptive software development model based on prototyping and quick feedback with less emphasis on specific planning. With rapid application development, Finsol developers can quickly make multiple iterations and updates to the software without starting from scratch. This helps ensure that the final outcome is more quality-focused and aligns with the end users’ requirements. Finsol’s RAD model is flexible for change in this model, changes are adoptable,it reduces development time and increases the reusability of features.",
        sectionListItems: [],
        sectionImage: images.application_development.applicationdevelopment4,
        sectionImageText: "",
      },
    },
    {
      id: "production-support",
      currentPage: "Production Support",
      innerBanner: {
        text1: "Production",
        text2: "Support",
        image: images.production_support.production_support_1,
        imageText: "",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Simplify your Production Support",
        sectionPara:
          "Proactive and personalized approach to Production Support and Maintenance. Our Finsol Team gives Production Support to make sure these applications run the whole time. This application can be of various types. Some of them are given below",
        sectionListItems: [
          {
            id: 1,
            text: "Python-based code for Machine Learning Model",
          },
          {
            id: 2,
            text: "Java and Spring-based REST API",
          },
          {
            id: 3,
            text: "React-based front-end application for outside customers",
          },
          {
            id: 4,
            text: "Mobile Application",
          },
        ],
        sectionImage: images.production_support.production_support_2,
        sectionImageText: "",
      },
      section2: {
        sectionTitle: "Adaptive Maintenance and Support",
        sectionPara:
          "Finsol will perform any required modifications to your applications that result from changes to the operating system, software dependencies, hardware, or business policies of a related product, keeping your system up to date with little to no downtime.",
        sectionImage: images.production_support.production_support_3,
        subSection: {
          title: "",
          text: "Perfective maintenance post-implementation to enhance the functionality and performance based on user-submitted feedback. Finsol uses powerful data analytics to assess your system’s performance, anticipate future issues, and take preemptive action that will minimize risk of operational failure.",
        },
      },
      section3: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Production Support",
        sectionPara:
          "In today’s world, many businesses and companies are developing their own applications in-house to support their business and improve the customer experience. When these applications are built, the company needs to provide continuous support to sustain the application in real-time. When the user base in applications grows, and it becomes more complex, they need more support for maintaining it.",
        sectionListItems: [],
        sectionImage: images.production_support.production_support_4,
        sectionImageText: "",
      },
    },

    {
      id: "project-management-office",
      currentPage: "Project Management Office",
      innerBanner: {
        text1: "Project Management",
        text2: "Office",
        image: images.project_management.project_management_1,
        imageText: "",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Project Management Office Service",
        sectionPara:
          "Today’s organizations seek to become more strategic and innovative to enhance business process performance. When it comes to client engagement while running multiple projects, organizations do encounter challenges.",

        sectionImage: images.project_management.project_management_2,
        sectionImageText: "",
      },
      section4: {
        sectionListItems: [
          {
            heading: "Program/Project Governance.",
          },
          {
            heading: "End-to-end project/program management.",
          },
          {
            heading: "Schedule, cost and time management.",
          },
          {
            heading: "Detailed status to steerco/team.",
          },
          {
            heading: "Proactive risk/issue management.",
          },
          {
            heading: "QBR and monthly metrics",
          },
        ],
      },
      section3: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Project Management Office (PMO) ",
        sectionPara:
          "Finsol believes PMO as an art of making a rainbow managing different colored process streams simultaneously optimizing the limited resources. Here at Our Finsol PMO’s Center of Excellence (CoE) we offer a practical project control framework to ensure better performance with a consistent approach to project management and are capable of juggling three balls which are time, cost and quality effectively. ",
        sectionListItems: [],
        sectionImage: images.project_management.project_management_3,
        sectionImageText: "",
      },
    },
    {
      id: "ui-ux",
      currentPage: "UI/UX",
      innerBanner: {
        text1: "UI/UX",
        text2: "",
        image: images.ui_ux.ui_img_1,
        imageText: "",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "UI/UX",
        sectionPara:
          "Finsol works on creating UI/ UX design for digital products with relevant design and development all towards one goal: a valuable, memorable, seamless user experience for the user. We design for web and mobile applications for different verticals and work with clients on wireframes before initiating application design.",
        sectionListItems: [
          {
            id: 1,
            text: "Our front-end development specialists are experts in key front-end languages HTML5, CSS, JavaScript",
          },
          {
            id: 2,
            text: "frameworks like jQuery, AngularJS, React.js, Next.js and Bootstrap.",
          },
        ],
        sectionImage: images.ui_ux.ui_img_2,
        sectionImageText: "",
      },
      section2: {
        sectionTitle: "Front-end development",
        sectionPara:
          "Front-end development is the technical implementation of the software’s user interface (UI). We construct highly responsive Graphical User Interfaces (GUI) that include elements like 2D/3D animations and embedded media, providing an organic, unified user experience throughout our custom solutions.",
        sectionImage: images.ui_ux.ui_img_3,
        subSection: {
          title: "",
          text: "Our team provides a user friendly and interactive visual design principles on websites and web applications in a positive and a coherent user experience. Our expertise in (UX) design is centered around the satisfaction the user experiences with your software",
        },
        cards: [],
      },
    },
    {
      id: "resource-augmentation",
      currentPage: "Resource Augmentation",
      innerBanner: {
        text1: "Resource",
        text2: "Augmentation",
        image: images.resource_augmentation.resource_augmentation_1,
        imageText: "",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Resource Augmentation",
        sectionPara:
          "Finsol provides highly competitive resources capable of delivering qualitative work at lowest cost with minimum development time.",
        sectionListItems: [
          {
            id: 1,
            text: "Strong HTML5 programming forms with CSS and Bootstrap the basis of web application programming and website building performance.",
          },
          {
            id: 2,
            text: "We combine Node.JS server-side scripting with tightly-written React.JS and Next.JS to create powerful web applicationsportable APIs and dynamic websites with expansive database functions.",
          },
          {
            id: 3,
            text: "Our core competencies are in application development and we have expertise in all major domains.",
          },
        ],
        sectionImage: images.resource_augmentation.resource_augmentation_2,
        sectionImageText: "",
      },
      section2: {
        sectionTitle:
          "We strive to exceed your expectations by breaking barriers and changing the game. ",
        sectionPara:
          "We are always available to help you find the perfect resource or provide the best solution!! Finsol provides highly competitive resources capable of delivering qualitative work at lowest cost with minimum development time.",
        sectionImage: images.resource_augmentation.resource_augmentation_3,
        subSection: {
          title: "",
          text: "We have experience with a wide variety of applications, from basic tools like CRMs to complex custom software solutions with specialized in banking, cards and healthcare that require deep knowledge of programming languages. We have executed many applications development projects that works as web based software, mobile app, data base application, enterprise business application and Custom made application. Our team consists of highly skilled and experienced professionals who are dedicated to bringing your ideas to life.",
        },
      },
      section3: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Your Ideas. Our Experience",
        sectionPara:
          "We use AJAX and XML to develop interoperable applications, schema constraints, data mapping tools, automation programs, and more platforms like Microsoft SQL Server, MySQL, and Oracle.Java helps us program dynamic Android applications, like mobile apps, e-commerce apps and location-based services. We also use Java to build content-rich, enterprise-grade web,mobile applications and web services, APIs during IT augmentations. We design responsive websites and mobile-first applications with feature-rich user functions and adaptive interfaces using JavaScript’s interpreted programming language.",
        sectionListItems: [],
        sectionImage: images.resource_augmentation.resource_augmentation_4,
        sectionImageText: "",
      },
    },

    {
      id: "migration-projects",
      currentPage: "Migration Projects In Cards & Payments",
      innerBanner: {
        text1: "Migration Projects In",
        text2: "Cards & Payments",
        image: images.migration_card.migration_card_1,
        imageText:
          "There are good reasons to switch to a more robust payment platform: it can deliver clear benefits like better approval ratios, enhanced reporting, quicker funding, global payment acceptance and improved security. But with your business humming along, you may not have made time for the transition.",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Migration Overview ",
        sectionPara:
          " There are good reasons to switch  a more robust payment platform. it can deliver clear benefits like better approval ratios, enhanced reporting, quicker funding, global payment acceptance and improved security.",
        sectionListItems: [
          {
            id: 1,
            text: "You contact your providers to request an export of your card data",
          },
          {
            id: 2,
            text: "We tokenize the card data for you and migrate the cards to Payments OS",
          },
          {
            id: 3,
            text: "You modify your code logic so that existing customers use the card information stored with Payments OS.",
          },
        ],
        sectionImage: images.migration_card.migration_card_2,
        sectionImageText: "",
      },
      section3: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Migrating Card Data ",
        sectionPara:
          "Moving from your current providers involves a three-step process that will go completely unnoticed to your customers (rest assured, they will not need to re-enter their card information at any stage during or after the move). Here the role of Finsol comes by mapping out a migration project that includes plenty of testing and a solid contingency plan, the transition can happen without any impact on your business or customer satisfaction.",
        sectionListItems: [],
        sectionImage: images.migration_card.migration_card_3,
        sectionImageText: "",
      },
    },
    {
      id: "business-as-usual",
      currentPage: "Business as Usual",
      innerBanner: {
        text1: "Business as",
        text2: "Usual",
        image: images.business.business_1,
        imageText: "",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Business-as-Usual (BAU) Support for Your IT Projects",
        sectionPara:
          "Toggling between Business-as-Usual (BAU) tasks and project work can be a tough balancing act, but it’s something that all organizations must deal with.",
        sectionListItems: [
          {
            id: 1,
            text: "Finsol's BAU work keeps the business operations running smoothly and profitably, companies who want to remain competitive and innovative, also need to work on IT projects.",
          },
          {
            id: 2,
            text: "IT project work allows enterprises to focus on developing new products and service offerings, as well as create solutions that improve efficiency within the business. ",
          },
          {
            id: 3,
            text: "IT projects to fall by the wayside has probably lost sight of their long-term goals for growth. ",
          },
        ],
        sectionImage: images.business.business_2,
        sectionImageText: "",
      },
      section2: {
        sectionTitle: "What is Business-as-Usual Work",
        sectionPara:
          "BAU work is any ongoing set of tasks that absolutely needs to be done on a regular basis to keep things running. Hence, BAU has no foreseeable end date.Finsol's Employees doing BAU work are usually the first to see which processes are no longer working and would require some changes. Finsol BAU teams tend to lean more towards mitigating risk and making sure that the organization maintains its stability.",
        sectionImage: images.business.business_3,
        subSection: {
          title: "",
          text: "BAU work is both predictable and repetitive, and is also easily identifiable because costs associated with it are often part of the company’s operating expenses. Some of the BAU operations taken up are user additions/deletions, configuration changes, updating security patches, helpdesk tasks.",
        },
      },
      section3: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Finding IT Support With FINSOL",
        sectionPara:
          "Both BAU and Project Work are vital for an organization to ensure that standards for quality,productivity, and security are met, while keeping the company’s long-term business objectives going.Acquiring FINSOL BAU managed services that can offer support for all your operational IT needs is one of the most cost-effective ways to ensure that both BAU and IT projects are getting done.",
        sectionListItems: [],
        sectionImage: images.business.business_4,
        sectionImageText: "",
      },
    },
    {
      id: "it-services-and-cloud-solution",
      currentPage: "it-services-and-cloud-solutions",
      innerBanner: {
        text1: "IT Services & ",
        text2: "Cloud Solutions",
        image: images.it_cloud.it_img_1,
        imageText: "",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "IT Services & Cloud Solutions",
        sectionPara:
          "Finsol consultancy offers clients services and tailored solutions for responsible financial management, company productivity management, continuous operational growth, responsiveness to customer needs, and techniques to attract and retain top professionals to help them achieve these goals.",
        sectionListItems: [
          {
            id: 1,
            text: "Finsol Analyze the IT infrastructure state with the focus on scalability, operational efficiency, and security.",
          },
          {
            id: 2,
            text: "Finsol Configure the tools for infrastructure monitoring and alerting.",
          },
          {
            id: 3,
            text: "Finsol optimize User administration, regular software configuration and updates.",
          },
        ],
        sectionImage: images.it_cloud.it_img_2,
        sectionImageText: "",
      },
      section2: {
        sectionTitle: "Clouds We Work With ",
        sectionPara:
          "With the growing diversity of offerings from different cloud providers, it’s often difficult and time-consuming for an organization to pick out the best-fitting cloud option. ",
        sectionImage: images.it_cloud.it_img_3,
        subSection: {
          title: "",
          text: "Being vendor neutral, Finsol weighs the pros and cons of all major clouds to help you find the best solution for your unique needs. AWS, Microsoft Azure and Google Cloud Platform",
        },
      },
      section3: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Benefits of opting Finsol's Cloud Solution",
        sectionPara:
          "Finsol's Cloud adoption strategy to plan, prioritize, and schedule IT infrastructure migration, cloud-native app and cloud data warehouse development. Feasibility assessment of your cloud initiatives that includes TCO and ROI estimation. Design and development of cloud apps and data warehouses including IT infrastructure design. Finsol Legacy infrastructure migration to the cloud via rehosting, re-platforming, refactoring. Knowledge transfer to IT support teams. Cloud and hybrid infrastructure configuration and code reviews.",
        sectionListItems: [],
        sectionImage: images.it_cloud.it_img_4,
        sectionImageText: "",
      },
    },

    {
      id: "b2b-projects-execution",
      currentPage: "B2B Project Execution",
      innerBanner: {
        text1: "B2B Projects",
        text2: "Execution",
        image: images.b2b_project.b2b_project_1,
        imageText: "",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "B2B Project Execution",
        sectionPara:
          "Finsol's B2B Implementing ERP systems for process industries, hospitals & E-commerce companies that optimize business processes and leverage corporate knowledge leading to competitive advantage.",
        sectionListItems: [
          {
            id: 1,
            text: "Finsol's B2B team specialized in providing business process analysis and system design, technical expertise, implementation, training, and support services.",
          },
          {
            id: 2,
            text: "Finsol B2B has a proven Implementation methodology and track records for delivering successful technology solutions for our customer.",
          },
          {
            id: 3,
            text: "Our ability to map our process to client’s needs ensures that we not only provide superior implementations, but also enable swift knowledge transfer to their staff.",
          },
        ],
        sectionImage: images.b2b_project.b2b_project_2,
        sectionImageText: "",
      },
      section2: {
        sectionTitle: "Why Finsol B2B?",
        sectionPara:
          "We have over 20 years of experience in addressing the needs of more than 150+ customers in India. Impressive record in delivering ERP solution, upgrades, integration, and customization. Strong Domain knowledge. Enterprise Project Management. Upgradations projects. Global Outsourcing Services.",
        sectionImage: images.b2b_project.b2b_project_3,
        subSection: {
          title: "",
          text: "Our team of experts have worked on Healthcare / Industry ERP projects of all types and sizes and are well equipped with the knowledge and expertise to handle any support query. We have a customer satisfaction score in excess of 95%, and we meet 100% of our support SLA agreement. ERP Implementations.",
        },
        cards: [],
      },
    },
    {
      id: "quality-assurance",
      currentPage: "Quality Assurance",
      innerBanner: {
        text1: "Quality",
        text2: "Assurance",
        image: images.quality_assurance.quality_assurance_1,
        imageText:
          "The Finsol consultancy's Quality Assurance division assists innovators in achieving corporate objectives and maintaining high quality. Let us understand your thoughts, and we will assist you in determining the qualities that are essential to your success.",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "QUALITY ASSURANCE",
        sectionPara:
          "The Finsol consultancy's Quality Assurance division assists innovators in achieving corporate objectives and maintaining high quality. Let us understand your thoughts, and we will assist you in determining the qualities that are essential to your success.",
        sectionListItems: [
          {
            id: 1,
            text: "Finsol provides support and cutting-edge technology to help you stay one step ahead of the competition.",
          },
          {
            id: 2,
            text: "We develop QA testing solutions and perform independent third-party testing services based on specific company projects to deliver successful results.",
          },
          {
            id: 3,
            text: "Finsol’s team provide world-class QA testing services for web and mobile applications, ensuring full compliance and high-quality software every time.",
          },
        ],
        sectionImage: images.quality_assurance.quality_assurance_2,
        sectionImageText: "",
      },

      section4: {
        sectionTitle: "FINSOL’S best practices for unique Quality Assurance",
        sectionpara: "",
        sectionListItems: [
          {
            heading: "Automate tests",
            text: " Automating tests that are repetitive and require minimal manual intervention increases your speed, agility, and productivity.",
          },
          {
            heading: "Shifting left",
            text: "Finsol Ensure that software defects are identified fast and early during the SDLC life cycle.",
          },
          {
            heading: "Feedback",
            text: "End-user feedback should drive continuous improvements of your QA strategy.",
          },
          {
            heading: "Purpose of testing",
            text: "Finsol Ensure that testing resources are used in meaningful ways and follow the code deployment structure of the Software Development Lifecycle.",
          },
          {
            heading: "Careful Automation",
            text: " Automating a waste process will only create more waste processes.",
          },
          {
            heading: "Keep the customer first",
            text: "Finally, a DevOps QA must keep the customer view of software quality at the forefront of all QA activities.",
          },
        ],
      },
    },
  ],
};

export default services;
