import React from "react";
import { useLocation } from "react-router";
import PolicyData from "../../../common/data/policy";
import InnerBannerSection from "../../utility/innerPageBanner";
function Policy() {
  const Data = PolicyData;
  const location = useLocation();
  return (
    <>
      {Data.policyPage.map((data, index) => (
        <div key={index + 1}>
          {"/policy/" + data.id === location.pathname && (
            <>
              <InnerBannerSection
                type={data.innerBanner.type}
                text1={data.innerBanner.text1}
                text2={data.innerBanner.text2}
                currentPage={data.currentPage}
              />
              {data.sections &&
                data.sections.map((section, index) => (
                  <section key={index + 1}>
                    <div className="container my-4">
                      <div className="row">
                        <div className="col-12">
                          {section.heading1 && (
                            <h1 className="main-title text-capitalize">
                              {section.heading1}
                            </h1>
                          )}
                          {section.heading2 && (
                            <h2 className="text-capitalize">
                              {section.heading2}
                            </h2>
                          )}
                          {section.heading3 && (
                            <h3 className="text-capitalize font-weight-bold">
                              {section.heading3}
                            </h3>
                          )}
                          {section.heading4 && (
                            <h4 className="text-capitalize text-bold">
                              {section.heading4}
                            </h4>
                          )}
                          {section.paragraph &&
                            section.paragraph.map((text, index) => (
                              <p key={index + 1}>{text}</p>
                            ))}
                          {section.bulletpoints && (
                            <ul className="bullet-points list-unstyled">
                              {section.bulletpoints &&
                                section.bulletpoints.map((point, index) => (
                                  <li key={index + 1}>{point}</li>
                                ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                ))}
            </>
          )}
        </div>
      ))}
    </>
  );
}

export default Policy;
