import emailjs from "@emailjs/browser";
import React, { useLayoutEffect, useRef } from "react";
import { useState } from "react";
import contact_img from "../../../common/assets/images/contact page/contact_img.svg";
import contactData from "../../../common/data/contact";
import InnerBannerSection from "../../utility/innerPageBanner";
function Contact() {
  const form = useRef();
  const [success, setSuccess] = useState(false);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_ugb811d",
        "template_h075pqn",
        form.current,
        "olQxEavQJrdJgx4gm"
      )
      .then(
        (result) => {
          if (result.text === 'OK') {
            console.log('success')
            setSuccess(true);
            e.target.reset();
            setTimeout(() => {
              setSuccess(false);
            }, 5000);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const data = contactData;
  return (
    <>
      <InnerBannerSection
        text1={data.innerBanner.text1}
        text2={data.innerBanner.text2}
        currentPage={data.currentPage}
        image={data.innerBanner.image}
        imageText={data.innerBanner.imageText}
        type={data.innerBanner.type}
      />
      <div className="contact-section-one">
        <div className="container">
          <div className="row">
            {data.address.map((address) => (
              <div key={address.id} className="col-md-4 ">
                <div className="address-block-two text-center mb-30 sm-mb-15">
                  <div className="icon d-flex align-items-center justify-content-center m-auto">
                    <img
                      width={60}
                      className="img-fluid"
                      src={address.icon}
                      alt={address.iconText}
                    />
                  </div>
                  <h5 className="title">{address.title}</h5>
                  <p>{address.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="container section-space ">
          <div className="row gx-xxl-5">
            <div className="col-lg-5 d-flex order-lg-last">
              <div className="form-style-one">
                <h3 className="form-title pb-30 lg-pb-15">Send Message.</h3>
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  method="post"
                  id="contact-form"
                  data-toggle="validator"
                >
                  <div className="messages"></div>
                  <div className="row controls">
                    <div className="col-12">
                      <div className="input-group-meta form-group mb-20">
                        <label>Name*</label>
                        <input
                          type="text"
                          placeholder="Enter Your Name"
                          name="user_name"
                          required="required"
                          data-error="Name is required."
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="input-group-meta form-group mb-20">
                        <label>Email*</label>
                        <input
                          type="email"
                          placeholder="Enter your Email ID"
                          name="user_email"
                          required="required"
                          data-error="Valid email is required."
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group-meta form-group mb-20">
                        <label>Phonenumber*</label>
                        <input
                          type="text"
                          maxLength={10}
                          placeholder="Enter your Phonenumber"
                          name="user_phone"
                          required="required"
                          data-error="Valid Phonenumber is required."
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group-meta form-group mb-20">
                        <textarea
                          placeholder="Your message*"
                          name="message"
                          required="required"
                          data-error="Please,leave us a message."
                        ></textarea>
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn-eight ripple-btn" type="submit">
                        Send Message
                      </button>
                    </div>
                    {success && 
                    <p className="alert alert-primary mt-4 ms-2" role="alert">
                       Thank you for Contacting Finsol. Our representative shall get in touch with you soon.
                    </p>
                    }
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-7 d-flex order-lg-first">
              <img src={contact_img} alt=" " />
            </div>
          </div>
        </div>
      </div>

      {/* {success && (
        <div className="custom-modal" style={{ display: "block" }}>
          <div className="custom-modal-content">
            <span
              className="custom-modal-close"
              onClick={() => setSuccess(false)}
            >
              &times;
            </span>
            <p>Thank you for Contacting Finsol. Our representative shall get in touch with you soon.</p>
          </div>
        </div>
      )} */}
      {/* <div className="fancy-short-banner-five">
        <div className="container">
          <div className="bg-wrapper">
            <div className="row align-items-center">
              <div className="col-lg-6 text-center text-lg-start">
                <h3 className="pe-xxl-5 md-pb-20">
                  Having any Query? Book an appointment.
                </h3>
              </div>
              <div className="col-lg-6 text-center text-lg-end">
                <a href="contact-us.html" className="msg-btn tran3s">
                  Send Us Message
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Contact;
