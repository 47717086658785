import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import productdata from "../../../common/data/product";
import InnerBannerSection from "../../utility/innerPageBanner";
import SectionFour from "../../utility/innerPageSections/sectionFour";
import SectionOne from "../../utility/innerPageSections/sectionOne";
import SectionThree from "../../utility/innerPageSections/sectionThree";
import SectionTwo from "../../utility/innerPageSections/sectionTwo";
function ProductPage() {
  const rawData = productdata;
  const subURL = "/product/";
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="main-page-wrapper">
      {rawData.service.map((data) => (
        <div key={data.id}>
          {subURL + data.id === location.pathname && (
            <InnerBannerSection
              text1={data.innerBanner.text1}
              text2={data.innerBanner.text2}
              currentPage={data.currentPage}
              image={data.innerBanner.image}
              imageText={data.innerBanner.imageText}
              type="left"
            />
          )}
          {subURL + data.id === location.pathname && data.section1 && (
            <SectionOne data={data.section1} />
          )}
          {subURL + data.id === location.pathname && data.section2 && (
            <SectionTwo data={data.section2} />
          )}
          {subURL + data.id === location.pathname && data.section3 && (
            <SectionThree data={data.section3} />
          )}
          {subURL + data.id === location.pathname && data.section4 && (
            <SectionFour data={data.section4} />
          )}
        </div>
      ))}
    </div>
  );
}

export default ProductPage;
