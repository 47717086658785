import React, { useLayoutEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import Images from "../../../common/data/images";

function InnerBannerSection(props) {
  const data = props;
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="theme-inner-banner">
      <div className="container">
        <div className="row">
          <div
            className={`align-self-center ${
              data.type === "left" ? "col-12 col-sm-8 col-md-6" : "col-12"
            }`}
          >
            <ScrollAnimation
              initiallyVisible
              animateIn="animate__fadeIn"
              animateOut="animate__fadeOut"
            >
              <h2
                className={`intro-title  text-capitalize ${
                  data.type === "left" ? "text-start" : "text-center"
                }`}
              >
                {data.text1} <span>{data.text2}</span>
              </h2>
              <ul
                className={`page-breadcrumb style-none d-flex ${
                  data.type === "left"
                    ? "justify-content-start"
                    : "justify-content-center"
                }`}
              >
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="current-page text-capitalize">
                  {data.currentPage}
                </li>
              </ul>
            </ScrollAnimation>
          </div>
          <div
            className={`${
              data.type === "left" ? "col-12 col-sm-4 col-md-6" : "d-none"
            }`}
          >
            <ScrollAnimation animateIn="animate__fadeIn" initiallyVisible>
              <img
                src={data.image}
                alt={data.imageText}
                className="image-fluid"
                style={{ maxHeight: 400, margin: "auto" }}
              />
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <img src={Images.shape.shape_38} alt="" className="shapes shape-one" />
      <img src={Images.shape.shape_39} alt="" className="shapes shape-two" />
    </div>
  );
}

export default InnerBannerSection;
