import images from "../../data/images";

const services = {
  service: [
    {
      id: "health-card-erp",
      currentPage: "Health Care ERP",
      innerBanner: {
        text1: "Health Care",
        text2: "ERP",
        image: images.health.erp_img_1,
        imageText: "",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Health Care ERP",
        sectionPara:
          "Health care ERP System is a broader ERP platform that’s categorically designed to assist the exclusive needs of health care businesses. It eliminates data silos , provides complete view of operations, improves process efficiency and helps in improving patient care.",

        sectionImage: images.health.erp_img_2,
        sectionImageText: "",
      },
      section4: {
        sectionTitle: "Health Care ERP System",
        sectionpara:
          "Considerably higher monetary controls because the data is centralized, built in and more accessable to everyone involved. It facilitates cash and credit transactions entry and effortlessly support the critical  stock management system. ",
        sectionListItems: [
          {
            text: "Finsol provides clinic management system that integrates every activity concerned with healthcare department.",
          },
          {
            text: "Patient data and information archives could be managed and utilized higher.",
          },
          {
            text: " Process automation eliminates the possibility of data duplication and reduces errors.",
          },
          {
            text: "ELECTRONIC MEDICAL REPORTS(EMR)  will increase the efficiency of accessing and sharing of data.",
          },
        ],
      },
    },
    {
      id: "erp",
      currentPage: "erp",
      innerBanner: {
        text1: "Enterprise resource planning",
        text2: " (ERP) ",
        image: images.sugar.sugar_img_1,
        imageText: "",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "Enterprise resource planning (ERP)",
        sectionPara:
          "FINSOL Enterprise resource planning (ERP) is a software system that helps organizations automate and manage core business processes for optimal performance.",
        sectionListItems: [
          {
            id: 1,
            text: "ERP software coordinates the flow of data between a company’s business processes, providing a single source of truth and streamlining operations across the enterprise",
          },
          {
            id: 2,
            text: "It’s capable of linking a company’s financials, supply chain, operations, commerce, reporting, manufacturing, and human resources activities on one platform",
          },
        ],
        sectionImage: images.sugar.sugar_img_2,
        sectionImageText: "",
      },
      section2: {
        sectionTitle:
          "Here are three ways FINSOL ERP system can improve your business",
        //sectionPara: "Drive optimal performance With Finsol ERP solutions ,you’ll access insights that enhance your decision making and reveal ways to improve operational performance going forward.Accelerate operational impact by connecting processes and data, you’ll bring more visibility and flexibility to employees, helping them take action quickly and deliver more value across the business.",
        sectionImage: images.sugar.sugar_img_3,
        subSection: {
          title: "",
          text: "",
        },
      },
      section4: {
        sectionListItems: [
          {
            heading: "Drive optimal performance",
            text: "With Finsol ERP solutions ,you’ll access insights that enhance your decision making and reveal ways to improve operational performance going forward. ",
          },
          {
            heading: "Accelerate operational impact",
            text: "By connecting processes and data, you’ll bring more visibility and flexibility to employees, helping them take action quickly and deliver more value across the business. ",
          },
          {
            heading: "Ensure business agility",
            text: "Finsol ERP is built to adapt to your needs and grow with you, helping you proactively prepare for and readily respond to any operational disruption or market change. ",
          },
        ],
      },
    },
    {
      id: "crm",
      currentPage: "CRM",
      innerBanner: {
        text1: "CRM",
        text2: "",
        image: images.crm.crm_img_1,
        imageText: "",
      },
      section1: {
        screenTitle: "WHY CHOOSE US",
        sectionTitle: "CRM",
        sectionPara:
          "Every customer or prospect interaction is important, but keeping track of them with address books and spreadsheets quickly becomes tedious. Customer relationship management software helps you stay on top of the task, and our highly rated Finsol CRM services are great place to start.",
        sectionImage: images.crm.crm_img_2,
        sectionImageText: "",
      },
      section2: {
        sectionTitle:
          "Let’s take a look at some of the ways Finsol CRM software can support your business goals",
        sectionPara: "",
        sectionImage: images.crm.crm_img_3,
        subSection: {
          title: "",
          text: "when it comes to advertising, when integrated with your Google Ads campaign, customer tracking can enable you to send a display ad across Google’s display network after your customer has visited a specific product page on your website.",
        },
        cards: [
          {
            id: 1,
            icon: images.crm.crm_icon_1,
            text: "Business Management ",
            para: " CRM software helps you keep everything in one place, allowing your sales, marketing, and service departments to work more efficiently.  ",
          },
          {
            id: 2,
            icon: images.crm.crm_icon_2,
            text: "Customer Tracking",
            para: " Customer tracking with Finsol CRM software helps support your lead generation, loyalty, and sales efforts that integrates with any social platforms .",
          },
          {
            id: 3,
            icon: images.crm.crm_icon_3,
            text: "Targeted Marketing",
            para: "Finsol CRM software can track every email offer click, website, and social click. Once you have the customer data, you can begin to formulate and target marketing.",
          },
        ],
      },
    },
  ],
};

export default services;
