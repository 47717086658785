import React from "react";
import { Link } from "react-router-dom";
import Home from "../../../common/data/home";
function Footer() {
  return (
    <div className="footer-style-four theme-basic-footer">
      <div className="container">
        <div className="inner-wrapper">
          {/* <div className="subscribe-area section-space">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="title-style-four sm-pb-20">
                  <h4 className="main-title">
                    Join Our <span>Newsletter</span> & Get updated.
                  </h4>
                </div>
              </div>
              <div className="col-md-6">
                <div className="subscribe-form">
                  <form action="#">
                    <input type="email" placeholder="Enter your email" />
                    <button className="ripple-btn tran3s">Subscribe</button>
                  </form>
                  <p>We only send interesting and relevant emails.</p>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-4 footer-intro mb-40 ">
              <div className="logo ">
                <a href="/">
                  <img src={Home.mainLogo} alt="" width="130" />
                </a>
              </div>
              <p>{Home.FooterSection.FooterText}</p>
              {/* <ul className="d-flex social-icon style-none not-included">
                {Home.FooterSection.socialMedia.map((social) => (
                  <li key={social.id}>
                    <a href={social.link}>
                      <i className={`${social.icon}`}></i>
                    </a>
                  </li>
                ))}
              </ul> */}
            </div>
        

            {Home.FooterSection.Footerlinks.map((FooterLink, index) => {
              return !FooterLink.multiCase ? (
                <div
                  key={index + 1}
                  className={`col-lg-${FooterLink.colSize} col-sm-4 ms-auto mb-30 `}
                >
                  <h5 className="footer-title">{FooterLink.text}</h5>
                  <ul className="footer-nav-link style-none">
                    {FooterLink.subLinks.map((subLink, index) => (
                      <li key={index + 1}>
                        <Link to={subLink.url}>{subLink.link}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div
                  key={index + 1}
                  className={`col-lg-${FooterLink.colSize} col-sm-4 ms-auto mb-30 `}
                >
                  {FooterLink.Links.map((Links, index) => (
                    <div key={index + 1}>
                      <h5 className="footer-title">{Links.text}</h5>
                      <ul
                        key={index + 1}
                        className="footer-nav-link style-none"
                      >
                        {Links.subLinks.map((subLinks, index) => (
                          <li key={index + 1}>
                            <Link to={subLinks.url}>{subLinks.link}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
          <div className="bottom-footer">
            <div className="d-lg-flex justify-content-between align-items-center">
              <ul className="order-lg-1 pb-15 pb-lg-0  d-flex justify-content-center footer-nav style-none">
                {Home.FooterSection.CopyrightBar.LegalLinks.map((LegalLink) => (
                  <li key={LegalLink.id}>
                    <Link to={LegalLink.Link}>{LegalLink.text}</Link>
                  </li>
                ))}
              </ul>
              <p className="copyright text-center order-lg-0 pb-15 pb-lg-0">
                Copyright @{new Date().getFullYear()} {Home.companyName}.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
