import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

function SectionThree(props) {
  const data = props.data;
  return (
    <div className="fancy-feature-twelve section-space">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-md-6 order-md-last">
            <ScrollAnimation animateIn="animate__fadeIn">
              <img
                src={data.sectionImage}
                alt={data.sectionImageText}
                className="transform-img-meta "
              />
            </ScrollAnimation>
          </div>
          <div className="col-xl-6 col-md-6 order-md-first">
            <div className="block-style-nine color-two">
              <div className="title-style-three pb-10">
                <ScrollAnimation animateIn="animate__fadeIn">
                  <div className="sc-title">{data.screenTitle}</div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeIn">
                  <h2 className="main-title">{data.sectionTitle}</h2>
                </ScrollAnimation>
              </div>
              <ScrollAnimation animateIn="animate__fadeIn">
                {data.sectionPara && <p>{data.sectionPara}</p>}
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn">
                {data.sectionListItems && (
                  <ul className="style-none list-item">
                    {data.sectionListItems.map((items) => (
                      <li key={items.id}>{items.text}</li>
                    ))}
                  </ul>
                )}
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionThree;
